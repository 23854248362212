export const filters = () => ({
        price: [0, Infinity],
        duration: [0, 9000],
        class: [],
        brandedFareName: [],
        refundable: [],
        stops: [],
        airline: [],
        startTime: [0, 1439],
        endTime: [0, 1439],
        flightNumber: null,
    }),
    searchRequest = () => ({
        convertToCurrency: null,
        routes: [],
        adults: 1,
        childrenAges: [],
        partialResponse: true,
    }),
    searchScheduleResponse = (routesCount = 1) => ({
        routes: [...new Array(routesCount)].map(() => searchResponse()),
    }),
    searchResponse = () => ({
        filters: filters(),
        offers: [],
    })
