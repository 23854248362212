import {
    initialiseStores,
    authStore,
    runtimeStore,
    cmsStore,
    hotelsRuntimeStore,
    flightsRuntimeStore,
    packagesRuntimeStore,
    activitiesRuntimeStore,
    transfersRuntimeStore,
    toursRuntimeStore,
    extraServicesRuntimeStore,
    supplierStore,
} from '~/utils/store-accessor'
import * as modulesList from '~/store/modules'
import Vuex from 'vuex'
const initializer = store => initialiseStores(store)
export const plugins = [initializer]
export * from '~/utils/store-accessor'

export const modules = modulesList
export const actions = {
    async nuxtServerInit() {
        await authStore.serverInit()
        await Promise.all([runtimeStore.serverInit(), cmsStore.serverInit()])
    },
    nuxtClientInit() {
        runtimeStore.clientInit()
        cmsStore.clientInit()
        hotelsRuntimeStore.clientInit()
        flightsRuntimeStore.clientInit()
        packagesRuntimeStore.clientInit()
        activitiesRuntimeStore.clientInit()
        transfersRuntimeStore.clientInit()
        toursRuntimeStore.clientInit()
        extraServicesRuntimeStore.clientInit()
        supplierStore.clientInit()
    },
}
export const createStore = () => {
    return new Vuex.Store({
        actions,
        modules,
        plugins,
    })
}
