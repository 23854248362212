export const filters = () => ({
        price: [0, Infinity],
        duration: [],
        dates: [],
        startTimes: [],
        types: [],
        themes: [],
        categories: [],
    }),
    searchRequest = () => ({
        convertToCurrency: null,
        cityId: null,
        startDate: null,
        endDate: null,
        adults: 1,
        childrenAges: [],
        partialResponse: true,
    }),
    searchResponse = () => ({
        filters: filters(),
        product: [],
    })
