export default function(products, sortFnName) {
    const minPrice = product => {
            return Math.min.apply(
                null,
                product.offers.map(offer => offer.price.amount)
            )
        },
        sortFn = {
            priceAsc: (offer1, offer2) => minPrice(offer1) - minPrice(offer2),
            priceDesc: (offer1, offer2) => minPrice(offer2) - minPrice(offer1),
            name: (offer1, offer2) => {
                if (offer1.info.name < offer2.info.name) return -1
                if (offer1.info.name > offer2.info.name) return 1
                return 0
            },
        }
    return products.concat().sort((offer1, offer2) => sortFn[sortFnName](offer1, offer2))
}
