import {interopDefault} from '@/utils/helpers'
const EmailTemplatesPage = () => interopDefault(import('~/pages/admin/EmailTemplatesPage')),
    EmailEditorPage = () => interopDefault(import('~/pages/admin/EmailEditorPage'))

export default [
    {
        path: '/email-templates',
        component: EmailTemplatesPage,
        name: 'emailTemplates',
    },
    {
        path: '/email-editor/:id',
        component: EmailEditorPage,
        name: 'emailEditor',
    },
]
