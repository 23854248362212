const middleware = {}

middleware['auth'] = require('../../src/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['members'] = require('../../src/middleware/members.js')
middleware['members'] = middleware['members'].default || middleware['members']

middleware['onlyRegistered'] = require('../../src/middleware/onlyRegistered.js')
middleware['onlyRegistered'] = middleware['onlyRegistered'].default || middleware['onlyRegistered']

middleware['orderAuth'] = require('../../src/middleware/orderAuth.js')
middleware['orderAuth'] = middleware['orderAuth'].default || middleware['orderAuth']

middleware['supplier'] = require('../../src/middleware/supplier.js')
middleware['supplier'] = middleware['supplier'].default || middleware['supplier']

export default middleware
