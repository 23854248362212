import {axiosInstance, setAxiosInstance} from '~/utils/axios-accessor'
import {authStore} from '~/store'
import * as api from '~/api'
import {stringify} from 'qs'
import {EventBus, LOGOUT_REDIRECT_EVENT} from '@/utils/event-bus'
import {formatAxiosError} from '@/utils/helpers'

export default function(ctx, inject) {
    if (ctx.$cookies.get('token')) {
        ctx.$axios.setHeader('Token', ctx.$cookies.get('token'))
    }
    ctx.$axios.setHeader('Accept-Language', ctx.app.i18n.locale)
    setAxiosInstance(ctx.$axios)
    ctx.$api = api
    inject('api', api)
    ctx.$axios.onRequest(config => {
        config.paramsSerializer = params => stringify(params, {arrayFormat: 'repeat'})
    })
    /*ctx.$axios.onResponse(response => {
        console.log(response, 'response');
        return Promise.resolve({data: 1111});
    });*/
    ctx.$axios.onError(async error => {
        if (
            error.response &&
            error.response.status === 401 &&
            !['/proxy/authorization', '/proxy/registration'].includes(error.config.url)
        ) {
            if (ctx.$cookies.get('role')) {
                EventBus.$emit(LOGOUT_REDIRECT_EVENT)
                await authStore.logOut()
                ctx.redirect('/')
                return error
            }
            error.config.headers.Token = await authStore.auth()
            return await ctx.$axios.request(error.config)
        }
        if (axiosInstance.isCancel(error)) {
            //TODO Right way - Promise.reject - need review all cancel cases
            return error
        }
        if (error.config.url.indexOf('/proxy/', 0) === -1 || !error.response) {
            ctx.$sentry.captureException(error)
            const errorData = formatAxiosError(error)
            delete errorData.request
            return Promise.reject(errorData)
        } else {
            if (error.response.status !== 401) {
                ctx.$sentry.captureException(error)
            }
            return Promise.reject(error.response.data)
        }
    })
    ctx.$axios.defaults.timeout = CONFIG.api.timeout + 5000
}
