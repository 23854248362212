export default function(offers, sortFnName) {
    // const minPrice = offer => {
    //         return Math.min.apply(
    //             null,
    //             offer.map(room => room.price)
    //         )
    //     },

    const bestSort = function(offers) {
        const offersLength = offers.length
        const prices = []
        const durations = []
        for (let i = 0; i < offersLength; i++) {
            if (offers[i].price) prices.push(offers[i].price.amount)
            durations.push(offers[i].sumDuration)
        }
        const uniquePrices = Array.from(new Set(prices))
        const uniqueDurations = Array.from(new Set(durations))
        uniquePrices.sort((a, b) => a - b)
        uniqueDurations.sort((a, b) => a - b)
        const flippedPrices = flipArray(uniquePrices)
        const flippedDurations = flipArray(uniqueDurations)
        const result = Array.from(offers)
        for (let i = 0; i < offersLength; i++) {
            result[i].bestCoef = flippedDurations[result[i].sumDuration]
            if (result[i].price) {
                result[i].bestCoef += flippedPrices[result[i].price.amount]
            }
        }
        result.sort((a, b) => a.bestCoef - b.bestCoef)
        return result
    }

    const flipArray = function(arr) {
        const length = arr.length
        const result = {}
        for (let i = 0; i < length; i++) {
            result[arr[i]] = i
        }
        return result
    }
    const sortFn = {
        priceAsc: (offer1, offer2) => {
            return offer1.price.amount - offer2.price.amount
        },
        priceDesc: (offer1, offer2) => {
            return offer2.price.amount - offer1.price.amount
        },
        priceDeltaAsc: (offer1, offer2) => {
            return offer1.deltaPrice.amount - offer2.deltaPrice.amount
        },
        priceDeltaDesc: (offer1, offer2) => {
            return offer2.deltaPrice.amount - offer1.deltaPrice.amount
        },
        durationAsc: (offer1, offer2) => {
            return offer1.sumDuration - offer2.sumDuration
        },
        durationDesc: (offer1, offer2) => {
            return offer2.sumDuration - offer1.sumDuration
        },
        airline: (offer1, offer2) => {
            if (
                offer1.itinerary[0].segments[0].marketingAirlineName <
                offer2.itinerary[0].segments[0].marketingAirlineName
            )
                return -1
            if (
                offer1.itinerary[0].segments[0].marketingAirlineName >
                offer2.itinerary[0].segments[0].marketingAirlineName
            )
                return 1
            return 0
        },
        departureTime: (offer1, offer2) => {
            const departure1 = new Date(offer1.itinerary[0].segments[0].departure.date)
            const departure2 = new Date(offer2.itinerary[0].segments[0].departure.date)
            return departure1.getTime() - departure2.getTime()
        },
        arrivalTime: (offer1, offer2) => {
            const lastItinerary1 = offer1.itinerary.length - 1
            const lastItinerary2 = offer1.itinerary.length - 1
            const arrival1 = new Date(
                offer1.itinerary[lastItinerary1].segments[
                    offer1.itinerary[lastItinerary1].segments.length - 1
                ].arrival.date
            )
            const arrival2 = new Date(
                offer2.itinerary[lastItinerary2].segments[
                    offer2.itinerary[lastItinerary2].segments.length - 1
                ].arrival.date
            )
            return arrival1.getTime() - arrival2.getTime()
        },
    }
    if (sortFnName !== 'best') {
        return offers.concat().sort((offer1, offer2) => sortFn[sortFnName](offer1, offer2))
    }
    return bestSort(offers)
}
