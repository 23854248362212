import auth from '~/store/modules/auth'
import cms from '~/store/modules/cms'
import runtime from '~/store/modules/runtime'
import persistent from '~/store/modules/persistent'
import adminRuntime from '~/store/modules/adminRuntime'

import hotels from '@/store/modules/hotels/hotels'
import hotelsRuntime from '@/store/modules/hotels/hotelsRuntime'

import transfers from '~/store/modules/transfers/transfers'
import transfersRuntime from '~/store/modules/transfers/transfersRuntime'

import activities from '~/store/modules/activities/activities'
import activitiesRuntime from '~/store/modules/activities/activitiesRuntime'

import flights from '~/store/modules/flights/flights'
import flightsRuntime from '~/store/modules/flights/flightsRuntime'

import packages from '~/store/modules/packages/packages'
import packagesRuntime from '~/store/modules/packages/packagesRuntime'

import tours from '~/store/modules/tours/tours'
import toursRuntime from '~/store/modules/tours/toursRuntime'

import extraServices from '~/store/modules/extraServices/extraServices'
import extraServicesRuntime from '~/store/modules/extraServices/extraServicesRuntime'

import supplier from '~/store/modules/supplier/supplier'

export {
    auth,
    hotels,
    hotelsRuntime,
    cms,
    runtime,
    persistent,
    transfers,
    transfersRuntime,
    activities,
    activitiesRuntime,
    adminRuntime,
    flights,
    flightsRuntime,
    packages,
    packagesRuntime,
    tours,
    toursRuntime,
    extraServices,
    extraServicesRuntime,
    supplier,
}
