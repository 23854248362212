import {setAppInstance, setCtxInstance} from '~/utils/app-accessor'
const workerpool = require('workerpool')

export default function(ctx, inject) {
    const pool = workerpool.pool()
    ctx.$pool = pool
    inject('pool', pool)
    //TODO Add context accessor
    setAppInstance(ctx.app)
    setCtxInstance(ctx)
}
