import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {persistentStore, extraServicesStore} from '@/store'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    FILTER_EVENT,
    RESET,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {searchResponse, searchRequest} from '@/utils/extraServices/extra-services-blank-states'
import {axiosInstance} from '@/utils/axios-accessor'
import {appInstance} from '@/utils/app-accessor'
import extraServicesFilter from '@/filters/extraServicesFilter'
import extraServicesSort from '@/filters/extraServicesSort'
import {addSeconds} from 'date-fns'

function newSearch(searchRequest) {
    //TODO Need to refactoring
    this.stopSearch()
    this.SET_OFFERS([])
    extraServicesStore.SET_SEARCH_RESPONSE(searchResponse())
    extraServicesStore.NEW_SEARCH(searchRequest)
}

@Module({name: 'extraServicesRuntime', stateFactory: true, namespaced: true})
export default class ExtraServicesRuntime extends VuexModule {
    city = {}
    offers = []
    searchActiveCount = 0
    partialSearchResultsCTS = null
    partialSearchTimeoutInstance = null

    @Mutation
    SET_CITY(val) {
        this.city = val
    }

    @Mutation
    SET_OFFERS(val) {
        this.offers = val
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource) {
        this.partialSearchResultsCTS = cancelTokenSource
    }

    @Mutation
    SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(timeoutInstance) {
        this.partialSearchTimeoutInstance = timeoutInstance
    }

    @Mutation
    RESET() {
        this.searchActiveCount = 0
    }

    @MutationAction({mutate: ['city']})
    async loadCity(id) {
        try {
            const {
                cities: [city],
            } = await appInstance.$api.locations.get({id, limitCities: 1})
            return {city}
        } catch (e) {
            return {city: {}}
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        extraServicesStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    async reload() {
        if (this.city.id) await this.context.dispatch('loadCity', this.city.id)
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
        this.RESET()
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await this.partialSearch(rq)
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action
    async partialSearch(rq) {
        let searchResponse

        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource)
            searchResponse = await appInstance.$api.searchOwnExtraService.get(rq)
            this.setSearchResponse(searchResponse)
        } catch (e) {
            if (e.status === 404) {
                //TODO Check 404 - searchKey has expired
                await this.partialSearch(rq)
                return
            } else {
                throw e
            }
        }
        if (searchResponse.products.length) {
            extraServicesStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), CONFIG.search.offersLifetime))
        }
    }

    @Action
    filter() {
        appInstance.$pool
            .exec(extraServicesFilter, [extraServicesStore.searchResponse.products, extraServicesStore.filters])
            .then(async filteredOffers => {
                this.sort(filteredOffers)
            })
            .catch(function(err) {
                console.error(err)
            })
            .then(function() {
                appInstance.$pool.terminate() // terminate all workers when done
            })
    }

    @Action
    sort(offers) {
        appInstance.$pool
            .exec(extraServicesSort, [offers, extraServicesStore.sortFnName])
            .then(sortedOffers => {
                this.SET_OFFERS(sortedOffers)
                EventBus.$emit(FILTER_EVENT)
            })
            .catch(function(err) {
                console.error(err)
            })
            .then(function() {
                appInstance.$pool.terminate() // terminate all workers when done
            })
    }

    @Action
    stopSearch() {
        if (this.partialSearchResultsCTS) this.partialSearchResultsCTS.cancel()
        if (this.partialSearchTimeoutInstance) this.partialSearchTimeoutInstance.cancel()
    }

    @Action
    setSearchResponse(searchResponse) {
        if (extraServicesStore.searchResponse.products.length !== searchResponse.products.length) {
            const initialPrice = extraServicesStore.searchResponse.filters.price,
                priceFilterValue = extraServicesStore.filters.price,
                newPriceFilterValue = priceFilterValue.slice()
            extraServicesStore.SET_SEARCH_RESPONSE(searchResponse)
            if (initialPrice[0] === priceFilterValue[0] || priceFilterValue[0] === 0) {
                newPriceFilterValue[0] = searchResponse.filters.price[0]
            }
            if (initialPrice[1] === priceFilterValue[1] || priceFilterValue[1] === Infinity) {
                newPriceFilterValue[1] = searchResponse.filters.price[1]
            }
            if (priceFilterValue[0] !== newPriceFilterValue[0] || priceFilterValue[1] !== newPriceFilterValue[1]) {
                extraServicesStore.SET_FILTER({key: 'price', value: newPriceFilterValue})
            }
            this.filter()
        }
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'extraServices', query}
        }
    }
}
