export default function(offers, filters) {
    // DEFINE FILTER FUNCTIONS

    // eslint-disable-next-line no-unused-vars
    const priceFilter = function(product, values) {
        product.offers = product.offers.filter(
            offer => offer.price.amount >= values[0] && offer.price.amount <= values[1]
        )
        return product.offers.length > 0
    }

    // eslint-disable-next-line no-unused-vars
    const nameFilter = function(product, values, key) {
        if (values) {
            return product.info.name.toLowerCase().includes(values.toLowerCase())
        }
        return true
    }

    // eslint-disable-next-line no-unused-vars
    const shortDescriptionFilter = function(product, values, key) {
        if (values) {
            return product.info.description.toLowerCase().includes(values.toLowerCase())
        }
        return true
    }

    //END DEFINE FILTER FUNCTIONS

    //START FILTER PROCESS
    return offers.filter(offer => {
        const result = []
        Object.keys(filters).forEach(key => {
            const functName = `${key}Filter`
            result.push(eval(functName)(offer, filters[key], key))
        })
        return result.every(el => el)
    })
}
