import {addDays, format} from 'date-fns'

export const searchRequest = () => ({
        convertToCurrency: null,
        packageTourId: null,
        startDateFrom: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        startDateTo: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
        adults: 2,
        childrenAges: [],
    }),
    searchResponse = () => ({
        offerKey: null,
        entries: [],
        info: {},
    }),
    packageTourInfo = () => ({
        entries: [],
        images: [],
        locations: [],
        availabilities: [],
    })
