import {axiosInstance} from '@/utils/axios-accessor'

async function get(params) {
    return await axiosInstance.$get('/api/searchAccommodationResults', {
        params,
        responseType: 'arraybuffer',
    })
}

export {get}
