import {Module, Mutation, Action, MutationAction} from 'vuex-module-decorators'
import {prepareAccommodationBook, tariffDetails, additionalOptions} from '@/api'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {arrayEquals, UTCToDate} from '@/utils/helpers'
import {hotelsRuntimeStore, persistentStore, runtimeStore} from '@/store'
import {filters, searchRequest, searchResponse} from '@/utils/hotels/hotels-blank-states'
import Vue from 'vue'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {salesTermsRsTransformer} from '@/utils/api-helpers'
import {convertPrice} from '@/utils/filters'

const singleHotelSearchResponse = () => ({
    offers: [],
})

@Module({name: 'hotels', stateFactory: true, namespaced: true})
export default class HotelsStore extends ProductStoreBase {
    /**
     * @type {{rooms: [string], partialResponse: boolean, convertToCurrency: null, endDate: string, cityId: null, startDate: string}}
     */
    searchRequest = searchRequest()
    searchResponse = searchResponse()
    searchExpirationTime = null
    filters = filters()
    sortFnName = 'priceAsc'
    singleHotelSearchRequest = null
    singleHotelSearchExpirationTime = null
    singleHotelSearchResponse = singleHotelSearchResponse()
    conditions = {}
    tariffDetails = {}
    tourists = []
    prepareBookRequest = []
    prepareBookResponse = {}
    offersToCompare = []
    bookingAdditionalOptions = []
    bookingExtraServices = []
    basket = []
    checkInOutExtraService = []

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    @Mutation
    SET_SORT_FN_NAME(sort) {
        this.sortFnName = sort
    }

    @Mutation
    SET_FILTER({key, value}) {
        this.filters[key] = value
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_REQUEST(rq) {
        this.singleHotelSearchRequest = rq
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_RESPONSE(rs) {
        this.singleHotelSearchResponse = rs
    }

    @Mutation
    SET_CONDITIONS(conditions) {
        Vue.set(this.conditions, conditions.offerKey, conditions)
    }

    @Mutation
    SET_TARIFF_DETAILS(tariffDetails) {
        this.tariffDetails[tariffDetails.offerKey] = tariffDetails
    }

    @Mutation
    SET_TOURIST_PROP({roomIndex, index, prop, val}) {
        Vue.set(this.tourists[roomIndex][index], prop, val)
    }

    @Mutation
    SET_TOURIST_PASSPORT_PROP({roomIndex, index, prop, val}) {
        Vue.set(this.tourists[roomIndex][index].passport, prop, val)
    }

    @Mutation
    SET_TOURIST({roomIndex, index, val}) {
        if (!this.tourists[roomIndex]) {
            Vue.set(this.tourists, roomIndex, [])
        }
        Vue.set(this.tourists[roomIndex], index, val)
    }

    @Mutation
    SET_TOURISTS(tourists) {
        this.tourists = tourists
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP(data) {
        if (!this.prepareBookRequest[data.index]) {
            Vue.set(this.prepareBookRequest, data.index, {})
        }
        Vue.set(this.prepareBookRequest[data.index], data.prop, data.val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_EXPIRATION_TIME(date) {
        this.singleHotelSearchExpirationTime = date
    }

    @Mutation
    SELECT_TO_COMPARE(hotelCode) {
        const index = this.offersToCompare.findIndex(el => {
            return el.hotelCode === hotelCode
        })
        if (index >= 0) {
            this.offersToCompare.splice(index, 1)
        } else {
            this.offersToCompare.push({hotelCode, rooms: []})
        }
    }

    @Mutation
    ADD_ROOM_TO_COMPARE(data) {
        const index = this.offersToCompare.findIndex(el => {
            return el.hotelCode === data.hotelCode
        })
        if (index >= 0) {
            this.offersToCompare[index].rooms = data.rooms
        } else {
            this.offersToCompare.push({hotelCode: data.hotelCode, rooms: data.rooms})
        }
    }

    @Mutation
    CLEAR_OFFERS_TO_COMPARE() {
        this.offersToCompare = []
    }

    @Mutation
    SET_BOOKING_ADDITIONAL_OPTIONS(options) {
        if (Object.keys(options).length) {
            this.bookingAdditionalOptions.push(options)
        }
    }

    @Mutation
    SET_BOOKING_EXTRA_SERVICE(service) {
        const index = this.bookingExtraServices.findIndex(el => el.type === service.type)
        if (index !== -1) {
            Vue.set(this.bookingExtraServices, index, service)
        } else {
            this.bookingExtraServices.push(service)
        }
    }

    @Mutation
    DELETE_EXTRA_SERVICE(type) {
        const index = this.bookingExtraServices.findIndex(el => el.type === type)
        if (index !== -1) {
            this.bookingExtraServices.splice(index, 1)
        }
    }

    @Mutation
    SET_CHECK_IN_OUT_EXTRA_SERVICE({roomIndex, type, time, serviceRPH, price, convertedPrice}) {
        const alreadyGot = this.checkInOutExtraService[roomIndex] || {}
        const extraService = {
            [type]: {
                serviceRPH,
                time,
                price,
                convertedPrice,
                roomIndex,
                text: type,
            },
        }
        Vue.set(this.checkInOutExtraService, roomIndex, {...alreadyGot, ...extraService})
    }

    @Mutation
    DELETE_CHECK_IN_OUT_EXTRA_SERVICE({roomIndex, type}) {
        Vue.delete(this.checkInOutExtraService[roomIndex], type)
    }

    @Mutation
    ADD_TO_BASKET(offerKeys) {
        this.basket = this.basket.filter(item => isBefore(new Date(), UTCToDate(parseISO(item.offer.expirationTime))))
        if (this.basket.findIndex(item => arrayEquals(item.offerKeys, offerKeys)) !== -1) return
        const roomOffers = [],
            agreedOfferKeys = []
        const findHotelAndRoomOffers = offers => {
            return offers.find(offer => {
                offer.rooms.forEach(room => {
                    offerKeys.forEach(offerKey => {
                        const selectedRoomOffer = room.groupedOffers.find(
                            groupedOffer => groupedOffer.offerKey === offerKey
                        )
                        if (selectedRoomOffer) {
                            const index = selectedRoomOffer.rph - 1
                            roomOffers[index] = room
                            agreedOfferKeys[index] = offerKey
                        }
                    })
                })
                return !!roomOffers.length
            })
        }
        let offer, searchRequest
        if (this.singleHotelSearchResponse) {
            offer = findHotelAndRoomOffers(this.singleHotelSearchResponse.offers)
        }
        if (offer) {
            searchRequest = Object.assign({}, this.singleHotelSearchRequest)
        } else {
            //TODO For HDI need load offers from cache
            offer = findHotelAndRoomOffers(hotelsRuntimeStore.filteredOffers)
            searchRequest = Object.assign({}, this.searchRequest)
        }
        this.basket.push({offerKeys: agreedOfferKeys, offer, roomOffers, searchRequest})
    }

    //TODO Need refactoring with other products
    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = this.basket.find(item => item.offerKeys.includes(offerKey))
        if (!basketItem) return
        const roomOffer = basketItem.roomOffers.find(
            room => room.groupedOffers.findIndex(groupedOffer => groupedOffer.offerKey === offerKey) !== -1
        )
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(roomOffer, 'currentPrice', price)
    }

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchRequest = searchRequest
        this.offersToCompare = []
        this.filters = filters()
        this.searchExpirationTime = null
    }

    @Mutation
    RESET() {
        this.searchRequest = searchRequest()
        this.singleHotelSearchRequest = null
        this.tourists = []
    }

    @Mutation
    NEW_SINGLE_HOTEL_SEARCH(searchRequest) {
        this.singleHotelSearchRequest = searchRequest
        this.singleHotelSearchResponse = singleHotelSearchResponse()
        this.singleHotelSearchExpirationTime = null
    }

    @MutationAction({mutate: ['bookingAdditionalOptions', 'bookingExtraServices', 'checkInOutExtraService']})
    clearBookingAdditionalOptions() {
        return {
            bookingAdditionalOptions: [],
            bookingExtraServices: [],
            checkInOutExtraService: [],
        }
    }

    @Action
    async getTariffDetails(rq) {
        let data = {
            offerKey: rq.offerKey,
            loading: true,
        }
        try {
            this.context.commit('SET_TARIFF_DETAILS', data)
            const rs = await tariffDetails.get(rq)
            Object.assign(data, rs, {loading: false})
            this.context.commit('SET_TARIFF_DETAILS', data)
        } catch (e) {
            Object.assign(data, {
                loading: false,
                errors: ['Offer was expired'],
            })
            this.context.commit('SET_TARIFF_DETAILS', data)
        }
    }

    @Action({rawError: true})
    async prepareBook(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_PREPARE_BOOK_REQUEST(rq)
            const rs = await prepareAccommodationBook.post(rq)
            this.SET_PREPARE_BOOK_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    @Action
    setFilter(filter) {
        this.context.commit('SET_FILTER', filter)
    }

    @Action
    async selectToCompare(hotelCode) {
        await this.context.commit('SELECT_TO_COMPARE', hotelCode)
    }

    @Action
    async loadExtraMealTypes({offerKey}) {
        try {
            const rs = await additionalOptions.get({offerKey})
            this.SET_BOOKING_ADDITIONAL_OPTIONS(rs)
        } catch (error) {
            console.error(error)
        }
    }

    get extraMealTypes() {
        return this.bookingAdditionalOptions.length
            ? this.bookingAdditionalOptions.map(options => options.alternativeMeals || [])
            : []
    }

    get searchRequestTourists() {
        return hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)
    }

    get singleHotelSearchRequestTourists() {
        return this.singleHotelSearchRequest
            ? hotelsRuntimeStore.searchRequestTouristsJSON(this.singleHotelSearchRequest)
            : []
    }

    get hasSingleHotelOffers() {
        return !!this.singleHotelSearchResponse.offers.length
    }

    get basketItem() {
        return offerKeys => this.basket.find(item => arrayEquals(item.offerKeys, offerKeys)) || {}
    }

    get isOffersExpired() {
        return () =>
            this.hasOffers && !hotelsRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }

    get isSingleHotelOffersExpired() {
        return () =>
            this.hasSingleHotelOffers &&
            !hotelsRuntimeStore.singleHotelSearchActive &&
            isAfter(new Date(), this.singleHotelSearchExpirationTime)
    }

    get hasOffers() {
        return !!this.searchResponse.offers.length
    }

    get bookingExtraPrice() {
        return this.bookingExtraServices.reduce(
            (total, service) => {
                total.amount += convertPrice(service.price).amount * service.quantity
                return total
            },
            {amount: 0, currency: persistentStore.currency}
        )
    }

    get orderTotalPrice() {
        return (roomOffers, prebooked) => {
            const originalCurrency = roomOffers[0].price.originalCurrency || roomOffers[0].price.currency
            const totalRoomsPrice = roomOffers.reduce(
                (total, {price, currentPrice, loyaltyPoints}) => {
                    const offerPrice = prebooked && currentPrice ? currentPrice : price
                    total.amount += convertPrice(offerPrice).amount
                    const roomOriginalCurrency = offerPrice.originalCurrency || offerPrice.currency,
                        roomOriginalAmount = offerPrice.originalAmount || offerPrice.amount
                    total.loyaltyPoints += loyaltyPoints
                    if (roomOriginalCurrency === originalCurrency) {
                        total.originalAmount += roomOriginalAmount
                    }
                    return total
                },
                {amount: 0, currency: persistentStore.currency, originalAmount: 0, originalCurrency, loyaltyPoints: 0}
            )
            if (prebooked) return totalRoomsPrice
            this.checkInOutPrice(totalRoomsPrice)
            return this.extraPrice(totalRoomsPrice)
        }
    }

    get extraServices() {
        return this.bookingAdditionalOptions.length
            ? this.bookingAdditionalOptions.map(options => options.extraServices || [])
            : []
    }

    get checkInOutPrice() {
        return total => {
            return this.checkInOutExtraService.reduce((total, {earlyCheckIn, lateCheckOut}) => {
                const serviceOriginalCurrencyIn = earlyCheckIn?.price?.currency
                const serviceOriginalCurrencyOut = lateCheckOut?.price?.currency
                const serviceOriginalCurrency = serviceOriginalCurrencyIn || serviceOriginalCurrencyOut

                const amountIn = earlyCheckIn?.price ? convertPrice(earlyCheckIn.price).amount : 0
                const amountOut = lateCheckOut?.price ? convertPrice(lateCheckOut.price).amount : 0

                const extraInRPH = earlyCheckIn?.serviceRPH || 1
                const extraOutRPH = lateCheckOut?.serviceRPH || 1

                total.amount = total.amount + amountIn * extraInRPH + amountOut * extraOutRPH

                if (total.originalCurrency) {
                    if (serviceOriginalCurrency === total.originalCurrency) {
                        const originalCheckInAmount = earlyCheckIn?.price?.amount || 0
                        const originalCheckOutAmount = lateCheckOut?.price?.amount || 0
                        total.originalAmount =
                            total.originalAmount +
                            originalCheckInAmount * extraInRPH +
                            originalCheckOutAmount * extraOutRPH
                    } else {
                        delete total.originalCurrency
                    }
                }
                return total
            }, total)
        }
    }
}
