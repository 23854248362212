export default function(offers, sortFnName) {
    const minPrice = product =>
            Math.min.apply(
                null,
                product.offers.map(offer => offer.price.amount)
            ),
        sortFn = {
            priceAsc: (offer1, offer2) => minPrice(offer1) - minPrice(offer2),
            priceDesc: (offer1, offer2) => minPrice(offer2) - minPrice(offer1),
        }
    return offers.concat().sort((offer1, offer2) => sortFn[sortFnName](offer1, offer2))
}
