import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {B2B_AUTH_EVENT, CHANGE_LOCALE_EVENT, CHANGE_PRICE_EVENT, EventBus, RESET, SEARCH_EVENT} from '@/utils/event-bus'
import {axiosInstance} from '@/utils/axios-accessor'
import {persistentStore, toursStore} from '@/utils/store-accessor'
import {appInstance} from '@/utils/app-accessor'
import {packageTourInfo, searchRequest} from '@/utils/tours/tours-blank-states'
import {addDays, format, isAfter} from 'date-fns'

function newSearch(rq) {
    this.stopSearch()
    toursStore.NEW_SEARCH(rq)
}

@Module({name: 'toursRuntime', stateFactory: true, namespaced: true})
export default class ToursRuntimeStore extends VuexModule {
    packageTours = []

    packageTourId = null
    packageTourInfo = packageTourInfo()

    searchActiveCount = 0
    searchCTS = null

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    UPDATE_CURRENT_DATE() {
        this.date = new Date()
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    SET_SEARCH_CTS(cancelTokenSource) {
        this.searchCTS = cancelTokenSource
    }

    @MutationAction({mutate: ['packageTours']})
    async loadPackageTours() {
        try {
            const packageTours = await appInstance.$api.packageTours.get()
            return {packageTours}
        } catch (e) {
            return {packageTours: []}
        }
    }

    @MutationAction({mutate: ['packageTourId', 'packageTourInfo']})
    async loadPackageTourInfo(packageTourId) {
        try {
            const packageTourInfo = await appInstance.$api.packageTourInfo.get({packageTourId})
            packageTourInfo.itineraryDays.sort((a, b) => a.number - b.number)
            const locationResponses = await Promise.all(
                packageTourInfo.locations.map(location => appInstance.$api.locations.get({id: location.id}))
            )
            packageTourInfo.locations = locationResponses.map(locationRs => locationRs.cities[0])
            packageTourInfo.availabilities.forEach(availability => {
                const defaultDate = addDays(new Date(), 1)
                if (isAfter(defaultDate, new Date(availability.startDateFrom))) {
                    availability.startDateFrom = format(defaultDate, 'yyyy-MM-dd')
                }
            })
            return {packageTourId, packageTourInfo}
        } catch (e) {
            return {packageTourId, packageTourInfo: packageTourInfo()}
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        toursStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        if (this.searchCTS) this.searchCTS.cancel()
        toursStore.RESET()
    }

    @Action
    async reload() {
        if (this.packageTourId) await this.loadPackageTourInfo(this.packageTourId)
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    stopSearch() {
        if (this.searchCTS) this.searchCTS.cancel()
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        const cancelTokenSource = axiosInstance.CancelToken.source()
        this.SET_SEARCH_CTS(cancelTokenSource)
        try {
            const searchResponse = await appInstance.$api.searchPackageTours.get(rq, cancelTokenSource.token)
            toursStore.SET_SEARCH_RESPONSE(searchResponse)
        } finally {
            this.STOP_SEARCH()
        }
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get touristsTotal() {
        return searchRequest => searchRequest.adults + searchRequest.childrenAges.length
    }

    get hasFlightOffers() {
        return offer => offer.entries.findIndex(entry => entry.type === 'FLIGHT') !== -1
    }

    get firstFlightOffer() {
        return offer => {
            const entry = offer.entries.find(entry => entry.type === 'FLIGHT')
            return entry?.offers[0]
        }
    }

    get hasTransferOffers() {
        return offer => offer.entries.findIndex(entry => entry.type === 'TRANSFER') !== -1
    }
}
