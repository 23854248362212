import {Module, VuexModule, MutationAction, Action} from 'vuex-module-decorators'
import {appInstance} from '@/utils/app-accessor'
import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

@Module({name: 'supplier', stateFactory: true, namespaced: true})
export default class SupplierStore extends VuexModule {
    orders = []
    contracts = []
    dailyRates = []
    availability = []
    services = []
    tariffs = []
    prices = []
    contract = {}

    @MutationAction({mutate: ['orders']})
    async loadOrders() {
        let startDate = new Date()
        startDate.setMonth(startDate.getMonth() - 6)
        const endDate = new Date()
        try {
            const rs = await appInstance.$api.orders.get({
                orderType: 'SUPPLIER',
                responseType: 'SHORT',
                createdDateFrom: startDate,
                createdDateTo: endDate,
            })
            return {orders: rs.orders}
        } catch (e) {
            return {orders: []}
        }
    }

    @MutationAction({mutate: ['contract']})
    async loadContract(contractId) {
        try {
            const rs = await appInstance.$api.contracts.get({id: contractId})
            return {contract: rs.contracts[0]}
        } catch (e) {
            return {contract: []}
        }
    }

    @MutationAction({mutate: ['contracts']})
    async loadContracts() {
        try {
            const rs = await appInstance.$api.contracts.get({limit: 1000})
            await Promise.all(
                rs.contracts.map(async contract => {
                    if (contract.serviceType == 'ownHotel') {
                        try {
                            let hotelInfo = await appInstance.$api.hotelInfo.get({
                                hotelId: contract.contractDetails.hotelId,
                            })
                            Object.assign(contract, {city: hotelInfo.hotelDescriptionInfo.address.cityName})
                            Object.assign(contract, {country: hotelInfo.hotelDescriptionInfo.address.countryName})
                        } catch (e) {
                            Object.assign(contract, {city: null})
                            Object.assign(contract, {country: null})
                        }
                    }

                    return contract
                })
            )
            return {contracts: rs.contracts}
        } catch (e) {
            return {contracts: []}
        }
    }

    @MutationAction({mutate: ['availability']})
    async loadAvailability(rq) {
        try {
            const rs = await appInstance.$api.availability.get(Object.assign(rq, {allotmentType: 'NETTO'}))
            return {availability: rs.inventories}
        } catch (e) {
            return {availability: []}
        }
    }

    @MutationAction({mutate: ['dailyRates', 'services', 'tariffs']})
    async loadDailyRates(rq) {
        try {
            const rs = await appInstance.$api.dailyRates.get(rq)
            return {dailyRates: rs.rates.dailyRates, services: rs.rates.services, tariffs: rs.rates.tariffs}
        } catch (e) {
            return {dailyRates: [], services: [], tariffs: []}
        }
    }

    @MutationAction({mutate: ['prices']})
    async loadPrices(rq) {
        try {
            const rs = await appInstance.$api.prices.get(rq)
            return {prices: rs}
        } catch (e) {
            return {prices: []}
        }
    }

    get availabilityServices() {
        if (!this.availability.length) {
            return []
        }

        return this.availability.filter(av => av.name == 'Allotment')[0].availabilityType
    }

    @Action
    clientInit() {
        EventBus.$on(CHANGE_LOCALE_EVENT, this.loadOrders)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.loadContracts)
    }
}
