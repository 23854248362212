export const filters = () => ({
        price: [0, Infinity],
    }),
    searchRequest = () => ({
        convertToCurrency: null,
        countryId: null,
        cityId: null,
        startDate: null,
        adults: 1,
        childrenAges: [],
    }),
    searchResponse = () => ({
        filters: filters(),
        products: [],
    })
