import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {cmsStore, runtimeStore} from '@/store'
const deepmerge = require('deepmerge')

import vuetifyEn from 'vuetify/es5/locale/en'
import vuetifyRu from 'vuetify/es5/locale/ru'
import vuetifyAr from 'vuetify/es5/locale/ar'
import custom from '~src/locales/custom'

Vue.use(VueI18n)

export default async ({app, req, nuxtState}) => {
    const numberFormats = {
        'en-US': {
            currency: {
                style: 'currency',
                currency: 'USD',
            },
        },
        'en-UK': {
            currency: {
                style: 'currency',
                currency: 'EUR',
            },
        },
        'ru-RU': {
            currency: {
                style: 'currency',
                currency: 'RUB',
            },
        },
    }

    // Set i18n instance on app

    VueI18n.prototype.getChoiceIndex = function(choice, choicesLength) {
        if (this.locale !== 'ru') {
            if (choice === 0) {
                return 0
            }
            if (choice === 1) {
                return choicesLength < 3 ? 0 : 1
            }
            return choicesLength < 3 ? 1 : 2
        }

        if (choice === 0) {
            return 0
        }

        if (choice % 10 === 1 && choice % 100 !== 11) {
            return 1
        }

        if ([2, 3, 4].includes(choice % 10) && ![12, 13, 14].includes(choice % 100)) {
            return 2
        }

        if (![5, 6, 7, 8, 9].includes(choice % 10) || ![11, 12, 13, 14].includes(choice % 100)) {
            return choicesLength < 4 ? 2 : 3
        }

        // const teen = choice > 10 && choice < 20
        // const endsWithOne = choice % 10 === 1

        // if (!teen && endsWithOne) {
        //     return 1
        // }

        // if (!teen && custom) {
        //     return 2
        // }

        // return choicesLength < 4 ? 2 : 3
    }
    // This way we can use it in middleware and pages asyncData/fetch
    const en = require('~/locales/en.json'),
        ru = require('~/locales/ru.json'),
        ar = require('~/locales/ar.json')
    let messages = {en, ru, ar}
    Object.assign(en, {
        transfers: require('~/locales/transfers/en.json'),
        activities: require('~/locales/activities/en.json'),
        admin: require('~/locales/admin/en.json'),
        flights: require('~/locales/flights/en.json'),
        packages: require('~/locales/packages/en.json'),
        tours: require('~/locales/tours/en.json'),
        extraServices: require('~/locales/extraServices/en.json'),
        supplier: require('~/locales/supplier/en.json'),
        $vuetify: vuetifyEn,
    })
    Object.assign(ru, {
        transfers: require('~/locales/transfers/ru.json'),
        activities: require('~/locales/activities/ru.json'),
        admin: require('~/locales/admin/ru.json'),
        flights: require('~/locales/flights/ru.json'),
        packages: require('~/locales/packages/ru.json'),
        tours: require('~/locales/tours/ru.json'),
        extraServices: require('~/locales/extraServices/ru.json'),
        supplier: require('~/locales/supplier/ru.json'),
        $vuetify: vuetifyRu,
    })
    Object.assign(ar, {
        $vuetify: vuetifyAr,
    })
    if (!nuxtState || !nuxtState.serverRendered) {
        try {
            const customTranslations = await app.$axios.$get('/cms/custom-translations')
            cmsStore.SET_CUSTOM_TRANSLATIONS(customTranslations)
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }
    messages = deepmerge(messages, custom)
    messages = deepmerge(messages, {ru: {$vuetify: require('~/locales/vuetify-ru.json')}})
    messages = deepmerge(messages, cmsStore.customTranslations)
    let locale = Object.values(runtimeStore.config.locales)[0]
    if (app.$cookies.get('locale')) {
        const language = app.$cookies.get('locale')
        if (Object.values(runtimeStore.config.locales).includes(language)) {
            locale = language
        } else {
            app.$cookies.set('locale', locale)
        }
    } else {
        if (process.server && req.headers['accept-language']) {
            const language = req.headers['accept-language']
                .split(',')[0]
                .toLocaleLowerCase()
                .substring(0, 2)
            if (Object.values(runtimeStore.config.locales).includes(language)) {
                locale = language
            }
        }
        app.$cookies.set('locale', locale)
    }
    app.i18n = new VueI18n({
        locale,
        fallbackLocale: 'en',
        messages,
        numberFormats,
    })

    app.i18n.path = link => {
        if (app.i18n.locale === app.i18n.fallbackLocale) {
            return `/${link}`
        }
        return `/${app.i18n.locale}/${link}`
    }
    //app.$moment.locale(app.i18n.locale);
}
