import {VuexModule, Mutation} from 'vuex-module-decorators'
import {convertPrice} from '@/utils/filters'
import {persistentStore} from '@/utils/store-accessor'

export default class ProductStoreBase extends VuexModule {
    searchPage = 1

    @Mutation
    SET_SEARCH_PAGE(page) {
        this.searchPage = page
    }

    @Mutation
    SET_SEARCH_RESPONSE(searchResponse) {
        this.searchResponse = searchResponse
    }

    get extraPrice() {
        return price => {
            return this.bookingExtraServices
                .filter(service => !service.mandatory)
                .reduce((total, {price, quantity}) => {
                    const serviceOriginalCurrency = price.originalCurrency || price.currency
                    let amount = convertPrice(price).amount,
                        serviceOriginalAmount = price.originalCurrency ? price.originalAmount : price.amount
                    if (quantity) {
                        amount = amount * quantity
                        serviceOriginalAmount = serviceOriginalAmount * quantity
                    }
                    total.amount += amount
                    if (total.originalCurrency) {
                        if (serviceOriginalCurrency === total.originalCurrency) {
                            total.originalAmount += serviceOriginalAmount
                        } else {
                            delete total.originalCurrency
                        }
                    }
                    return total
                }, price)
        }
    }

    get orderTotalPrice() {
        return ({price, currentPrice}, prebooked) => {
            const offerPrice = prebooked && currentPrice ? currentPrice : price,
                originalCurrency = offerPrice.originalCurrency || offerPrice.currency,
                originalAmount = offerPrice.originalAmount || offerPrice.amount,
                totalOfferPrice = {
                    amount: convertPrice(offerPrice).amount,
                    currency: persistentStore.currency,
                    originalAmount,
                    originalCurrency,
                }
            if (prebooked) return totalOfferPrice
            return this.extraPrice(totalOfferPrice)
        }
    }
}
