import {getModule} from 'vuex-module-decorators'
import {
    auth,
    hotels,
    hotelsRuntime,
    cms,
    runtime,
    persistent,
    transfers,
    transfersRuntime,
    activities,
    activitiesRuntime,
    adminRuntime,
    flights,
    flightsRuntime,
    packages,
    packagesRuntime,
    tours,
    toursRuntime,
    extraServices,
    extraServicesRuntime,
    supplier,
} from '~/store/modules'

/**
 * @type AuthStore
 */
let authStore

/**
 * @type HotelsStore
 */
let hotelsStore

/**
 * @type HotelsRuntimeStore
 */
let hotelsRuntimeStore

/**
 * @type CmsStore
 */
let cmsStore

/**
 * @type RuntimeStore
 */
let runtimeStore

/**
 * @type PersistentStore
 */
let persistentStore

/**
 * @type TransfersStore
 */
let transfersStore

/**
 * @type TransfersRuntimeStore
 */
let transfersRuntimeStore

/**
 * @type ActivitiesStore
 */
let activitiesStore

/**
 * @type ActivitiesRuntimeStore
 */
let activitiesRuntimeStore

/**
 * @type AdminRuntimeStore
 */
let adminRuntimeStore

/**
 * @type FlightsStore
 */
let flightsStore

/**
 * @type FlightsRuntimeStore
 */
let flightsRuntimeStore

/**
 * @type PackagesStore
 */
let packagesStore

/**
 * @type PackagesRuntimeStore
 */
let packagesRuntimeStore

/**
 * @type ToursStore
 */
let toursStore

/**
 * @type ToursRuntimeStore
 */
let toursRuntimeStore

/**
 * @type ExtraServiceStore
 */
let extraServicesStore

/**
 * @type ExtraServicesRuntime
 */
let extraServicesRuntimeStore

/**
 * @type SupplierStore
 */
let supplierStore

function initialiseStores(store) {
    authStore = getModule(auth, store)
    cmsStore = getModule(cms, store)
    adminRuntimeStore = getModule(adminRuntime, store)

    persistentStore = getModule(persistent, store)
    runtimeStore = getModule(runtime, store)

    hotelsStore = getModule(hotels, store)
    hotelsRuntimeStore = getModule(hotelsRuntime, store)

    transfersStore = getModule(transfers, store)
    transfersRuntimeStore = getModule(transfersRuntime, store)

    activitiesStore = getModule(activities, store)
    activitiesRuntimeStore = getModule(activitiesRuntime, store)

    flightsStore = getModule(flights, store)
    flightsRuntimeStore = getModule(flightsRuntime, store)

    packagesStore = getModule(packages, store)
    packagesRuntimeStore = getModule(packagesRuntime, store)

    toursStore = getModule(tours, store)
    toursRuntimeStore = getModule(toursRuntime, store)

    extraServicesStore = getModule(extraServices, store)
    extraServicesRuntimeStore = getModule(extraServicesRuntime, store)

    supplierStore = getModule(supplier, store)
}

export {
    initialiseStores,
    authStore,
    hotelsStore,
    hotelsRuntimeStore,
    cmsStore,
    runtimeStore,
    persistentStore,
    transfersStore,
    transfersRuntimeStore,
    activitiesStore,
    activitiesRuntimeStore,
    adminRuntimeStore,
    flightsStore,
    flightsRuntimeStore,
    packagesStore,
    packagesRuntimeStore,
    toursStore,
    toursRuntimeStore,
    extraServicesStore,
    extraServicesRuntimeStore,
    supplierStore,
}
