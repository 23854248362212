import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {persistentStore, runtimeStore, transfersStore} from '@/store'
import {B2B_AUTH_EVENT, CHANGE_PRICE_EVENT, EventBus, FILTER_EVENT, RESET, SEARCH_EVENT} from '@/utils/event-bus'
import {searchResponse} from '@/utils/transfers/transfers-blank-states'
import {axiosInstance} from '@/utils/axios-accessor'
import {later} from '@/utils/helpers'
import {appInstance} from '@/utils/app-accessor'
import transfersFilter from '@/filters/transfersFilter'
import transfersSort from '@/filters/transfersSort'
import {addSeconds} from 'date-fns'
import {searchRequest} from '@/utils/flights/flights-blank-states'

function setSearchResponse(searchResponse) {
    if (transfersStore.searchResponse.product.length !== searchResponse.product.length) {
        const initialPrice = transfersStore.searchResponse.filters.price,
            priceFilterValue = transfersStore.filters.price,
            newPriceFilterValue = priceFilterValue.slice()
        transfersStore.SET_SEARCH_RESPONSE(searchResponse)
        if (initialPrice[0] === priceFilterValue[0] || priceFilterValue[0] === 0) {
            newPriceFilterValue[0] = searchResponse.filters.price[0]
        }
        if (initialPrice[1] === priceFilterValue[1] || priceFilterValue[1] === Infinity) {
            newPriceFilterValue[1] = searchResponse.filters.price[1]
        }
        if (priceFilterValue[0] !== newPriceFilterValue[0] || priceFilterValue[1] !== newPriceFilterValue[1]) {
            transfersStore.SET_FILTER({key: 'price', value: newPriceFilterValue})
        }
        this.filter()
    }
}

//TODO Refactoring partial search with all products
async function partialSearch(rq) {
    let searchResponse
    const {searchKey} = await appInstance.$api.searchTransfer.get(rq)
    do {
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource)
            searchResponse = await appInstance.$api.partialSearchResults.get(
                {searchKey, product: 'transfer'},
                cancelTokenSource.token
            )
            setSearchResponse.call(this, searchResponse)
            if (!searchResponse.processed) {
                this.SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(
                    later(searchResponse.product.length > 1 ? CONFIG.search.partialResponseInterval : 3000)
                )
                await this.partialSearchTimeoutInstance.promise
            }
        } catch (e) {
            if (!e) {
                break
            } else if (e.status === 404) {
                //TODO Check 404 - searchKey has expired
                await partialSearch.call(this, rq)
                return
            } else {
                throw e
            }
        }
    } while (!searchResponse.processed)
    if (searchResponse.product.length) {
        transfersStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), CONFIG.search.offersLifetime))
    }
}

function newSearch(searchRequest) {
    //TODO Need to refactoring
    this.stopSearch()
    this.SET_OFFERS([])
    transfersStore.SET_SEARCH_RESPONSE(searchResponse())
    transfersStore.NEW_SEARCH(searchRequest)
}

async function loadPoint(point, searchRequest) {
    try {
        let hotelCode, place, cityId
        if (point === 'arrivalPoint') {
            hotelCode = 'arrivalHotelCode'
            place = 'arrivalPlace'
            cityId = 'arrivalCityId'
        } else {
            hotelCode = 'departureHotelCode'
            place = 'departurePlace'
            cityId = 'departureCityId'
        }
        if (searchRequest[hotelCode]) {
            const rq = {
                pattern: searchRequest[hotelCode],
                limit: 1,
            }
            const rs = await appInstance.$api.suggestHotels.get(rq)
            //TODO refactor with autocomplete
            rs.hotels.forEach(hotel => {
                const location = hotel.location
                delete hotel.location
                Object.assign(hotel, location, {parentName: location.countryName, type: 'hotel'})
            })
            return {[point]: rs.hotels[0]}
        } else if (searchRequest[place] === 'hotel') {
            const rq = {
                id: searchRequest[cityId],
                limitCities: 1,
            }
            const rs = await appInstance.$api.locations.get(rq)
            return {[point]: rs.cities[0]}
        } else {
            const rq = {
                iataCode: searchRequest[place],
                limit: 1,
            }
            // if (isStoreDataPersist(cookie, rq)) return
            const rs = await appInstance.$api.airports.get(rq)
            //TODO refactor with autocomplete
            rs.forEach(airport => {
                Object.assign(airport, {parentName: airport.countryName})
            })
            return {[point]: rs[0]}
        }
    } catch (e) {
        return {[point]: {}}
    }
}

@Module({name: 'transfersRuntime', stateFactory: true, namespaced: true})
export default class TransfersRuntimeStore extends VuexModule {
    departurePoint = {}
    arrivalPoint = {}

    offers = []
    searchActiveCount = 0
    partialSearchResultsCTS = null
    partialSearchTimeoutInstance = null

    @Mutation
    SET_DEPARTURE_POINT(val) {
        this.departurePoint = val
    }

    @Mutation
    SET_ARRIVAL_POINT(val) {
        this.arrivalPoint = val
    }

    @Mutation
    SET_OFFERS(val) {
        this.offers = val
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource) {
        this.partialSearchResultsCTS = cancelTokenSource
    }

    @Mutation
    SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(timeoutInstance) {
        this.partialSearchTimeoutInstance = timeoutInstance
    }

    @Mutation
    RESET() {
        this.searchActiveCount = 0
    }

    @MutationAction({mutate: ['departurePoint']})
    async loadDeparturePoint(searchRequest) {
        return await loadPoint.call(this, 'departurePoint', searchRequest)
    }

    @MutationAction({mutate: ['arrivalPoint']})
    async loadArrivalPoint(searchRequest) {
        return await loadPoint.call(this, 'arrivalPoint', searchRequest)
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        //TODO Add CHANGE_LOCALE_EVENT handler
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        transfersStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
        this.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await partialSearch.call(this, rq)
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action
    filter() {
        appInstance.$pool
            .exec(transfersFilter, [transfersStore.searchResponse.product, transfersStore.filters])
            .then(async filteredOffers => {
                this.sort(filteredOffers)
            })
            .catch(function(err) {
                console.error(err)
            })
            .then(function() {
                appInstance.$pool.terminate() // terminate all workers when done
            })
    }

    @Action
    sort(offers) {
        appInstance.$pool
            .exec(transfersSort, [offers, transfersStore.sortFnName])
            .then(sortedOffers => {
                this.SET_OFFERS(sortedOffers)
                EventBus.$emit(FILTER_EVENT)
            })
            .catch(function(err) {
                console.error(err)
            })
            .then(function() {
                appInstance.$pool.terminate() // terminate all workers when done
            })
    }

    @Action
    stopSearch() {
        if (this.partialSearchResultsCTS) this.partialSearchResultsCTS.cancel()
        if (this.partialSearchTimeoutInstance) this.partialSearchTimeoutInstance.cancel()
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'transfers', query}
        }
    }

    get ownProduct() {
        return product => runtimeStore.ownProduct(product?.info?.supplierCode)
    }

    get offerMainImage() {
        return (offer, info) => {
            if (!info.images || !info.images.length) return {url: null}
            let mainImage = info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && info.images.length > 0) {
                mainImage = info.images[0]
            }
            if (mainImage.linkedEntityName && mainImage.linkedEntityName !== offer.info.description) {
                mainImage = info.images.find(image => image.linkedEntityName === offer.info.description) || mainImage
            }
            return mainImage
        }
    }
}
