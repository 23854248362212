import {Module, VuexModule, Mutation, Action, MutationAction} from 'vuex-module-decorators'
import Vue from 'vue'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    FILTER_EVENT,
    RESET,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {hotelsStore, persistentStore, runtimeStore} from '@/store'
import {
    hotelInfoRequest,
    hotelInfoResponse,
    searchRequest,
    searchResponse,
    filters,
} from '@/utils/hotels/hotels-blank-states'
import {axiosInstance} from '@/utils/axios-accessor'
import {encodeSupplierCode, isEqual, later, matches} from '@/utils/helpers'
import {addSeconds} from 'date-fns'
import {appInstance} from '@/utils/app-accessor'
import HotelsWorker from 'worker-loader!@/filters/hotelsWorker'
import {convertPrice} from '@/utils/filters'
import {sumPrice} from '@/utils/api-helpers'

let hotelsWorker

function newSearch(searchRequest) {
    this.stopSearch()
    this.SET_FILTERED_OFFERS([])
    hotelsStore.SET_SEARCH_RESPONSE(searchResponse())
    hotelsStore.NEW_SEARCH(searchRequest)
}

function newSingleHotelSearch(searchRequest) {
    this.stopSingleHotelSearch()
    hotelsStore.NEW_SINGLE_HOTEL_SEARCH(searchRequest)
}

function setSearchResponse(searchResponse) {
    //TODO Need to some refactoring
    if (hotelsStore.searchResponse.offers.length === searchResponse.offers.length) return
    const initialPrice = hotelsStore.searchResponse.filters.price,
        priceFilterValue = hotelsStore.filters.price,
        newPriceFilterValue = priceFilterValue.slice()
    if (initialPrice[0] === priceFilterValue[0] || priceFilterValue[0] === 0) {
        newPriceFilterValue[0] = searchResponse.filters.price[0]
    }
    if (initialPrice[1] === priceFilterValue[1] || priceFilterValue[1] === Infinity) {
        newPriceFilterValue[1] = searchResponse.filters.price[1]
    }
    if (priceFilterValue[0] !== newPriceFilterValue[0] || priceFilterValue[1] !== newPriceFilterValue[1]) {
        hotelsStore.SET_FILTER({key: 'price', value: newPriceFilterValue})
    }
    const {offers, ...sr} = searchResponse
    sr.offers = new Array(offers.length)
    hotelsStore.SET_SEARCH_RESPONSE(sr)
    appInstance.$localForage.setItem('hotels', offers)
    this.load(offers)
}

async function partialSearch(request) {
    const searchRequest = {...request}
    if (runtimeStore.config.searchAccommodationByDistance && searchRequest.latitude && searchRequest.longitude) {
        delete searchRequest.cityId
    }
    const {searchKey} = await appInstance.$api.searchAccommodation.get(searchRequest)
    let searchResponse
    do {
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource)
            //hotelsWorker.postMessage({action: 'search', searchKey, token: this.store.$cookies.get('token')})
            searchResponse = await appInstance.$api.partialSearchResults.get({searchKey}, cancelTokenSource.token)
            if (!searchResponse) break
            setSearchResponse.call(this, searchResponse)
            if (!searchResponse.processed) {
                this.SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(
                    later(searchResponse.offers.length > 1 ? CONFIG.search.partialResponseInterval : 3000)
                )
                await this.partialSearchTimeoutInstance.promise
            }
        } catch (e) {
            if (!e) {
                break
            } else if (e.status === 404) {
                //TODO Check 404 - searchKey has expired
                await partialSearch.call(this, searchRequest)
                return
            } else {
                throw e
            }
        }
    } while (!searchResponse.processed)
    if (searchResponse.offers.length) {
        hotelsStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), CONFIG.search.offersLifetime))
    }
    return searchResponse
}

//TODO need refactoring with partialSearch
async function singleHotelSearch(searchRequest) {
    const {searchKey} = await appInstance.$api.searchAccommodation.get(searchRequest)
    let searchResponse
    do {
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_SINGLE_HOTEL_SEARCH_CTS(cancelTokenSource)
            searchResponse = await appInstance.$api.partialSearchResults.get({searchKey}, cancelTokenSource.token)
            if (!searchResponse) break
            hotelsStore.SET_SINGLE_HOTEL_SEARCH_RESPONSE(searchResponse)
            if (!searchResponse.processed) {
                this.SET_SINGLE_HOTEL_SEARCH_TIMEOUT_INSTANCE(
                    later(searchResponse.offers.length > 1 ? CONFIG.search.partialResponseInterval : 3000)
                )
                await this.singleHotelSearchTimeoutInstance.promise
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
            if (!e) {
                break
            } else if (e.status === 404) {
                //TODO Check 404 - searchKey has expired
                await singleHotelSearch.call(this, searchRequest)
                return
            } else {
                throw e
            }
        }
    } while (!searchResponse.processed)
    if (searchResponse.offers.length) {
        hotelsStore.SET_SINGLE_HOTEL_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), CONFIG.search.offersLifetime))
    }
    return searchResponse
}

@Module({name: 'hotelsRuntime', stateFactory: true, namespaced: true})
export default class HotelsRuntimeStore extends VuexModule {
    //TODO Rename to runtimeOffers (not only filtered - sorted)
    filteredOffers = []

    searchActiveCount = 0
    partialSearchResultsCTS = null
    partialSearchTimeoutInstance = null

    singleHotelSearchActiveCount = 0
    singleHotelSearchCTS = null
    singleHotelSearchTimeoutInstance = null

    updateActiveOffers = []
    bookingActive = false
    city = {}
    hotelInfoRequest = hotelInfoRequest()
    hotelInfoResponse = hotelInfoResponse()

    filterActiveCount = 0

    @Mutation
    START_FILTER() {
        this.filterActiveCount++
    }

    @Mutation
    STOP_FILTER() {
        this.filterActiveCount--
    }

    @Mutation
    SET_CITY(city) {
        this.city = city
    }

    @Mutation
    SET_BOOKING_ACTIVE(active) {
        this.bookingActive = active
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
        this.partialSearchResultsCTS = null
        this.partialSearchTimeoutInstance = null
    }

    @Mutation
    START_SINGLE_HOTEL_SEARCH() {
        this.singleHotelSearchActiveCount++
    }

    @Mutation
    STOP_SINGLE_HOTEL_SEARCH() {
        this.singleHotelSearchActiveCount--
        this.singleHotelSearchCTS = null
        this.singleHotelSearchTimeoutInstance = null
    }

    @Mutation
    SET_FILTERED_OFFERS(filteredOffers) {
        this.filteredOffers = filteredOffers
    }

    @Mutation
    ADD_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode}) {
        this.updateActiveOffers.push({supplierCode, cityCode, hotelCode})
    }

    @Mutation
    REMOVE_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode}) {
        this.updateActiveOffers = this.updateActiveOffers.filter(
            item => !matches(item, {supplierCode, cityCode, hotelCode})
        )
    }

    @Mutation
    UPDATE_ROOMS({supplierCode, cityCode, hotelCode, rooms}) {
        const hotel = this.filteredOffers.find(
            offer => offer.supplierCode === supplierCode && offer.cityCode === cityCode && offer.hotelCode === hotelCode
        )
        if (!hotel) return
        hotel.rooms = rooms
        Vue.set(hotel, 'updatedRooms', true)
    }

    @Mutation
    SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource) {
        this.partialSearchResultsCTS = cancelTokenSource
    }

    @Mutation
    SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(timeoutInstance) {
        this.partialSearchTimeoutInstance = timeoutInstance
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_CTS(cancelTokenSource) {
        this.singleHotelSearchCTS = cancelTokenSource
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_TIMEOUT_INSTANCE(timeoutInstance) {
        this.singleHotelSearchTimeoutInstance = timeoutInstance
    }

    @MutationAction({mutate: ['city']})
    async loadCity(id) {
        try {
            const {cities} = await appInstance.$api.locations.get({id, limitCities: 1})
            return {city: cities[0]}
        } catch (e) {
            return {city: {}}
        }
    }

    @MutationAction({mutate: ['hotelInfoResponse', 'hotelInfoRequest']})
    async loadHotelInfo(rq) {
        try {
            const rs = await appInstance.$api.hotelInfo.get(rq)
            return {hotelInfoResponse: rs, hotelInfoRequest: rq}
        } catch (e) {
            return {hotelInfoResponse: hotelInfoResponse(), hotelInfoRequest: hotelInfoRequest()}
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        hotelsWorker = new HotelsWorker()
        hotelsWorker.onmessage = ({data}) => {
            if (data === 'load') {
                console.timeEnd('load')
                if (isEqual({...filters(), price: hotelsStore.searchResponse.filters.price}, hotelsStore.filters)) {
                    this.sort()
                } else {
                    this.filter()
                }
                return
            } else if (data === 'refresh') {
                console.timeEnd('load')
                return
            }
            this.STOP_FILTER()
            console.timeEnd('filter')
            if (this.filterActive) return
            //this.SET_FILTERED_OFFERS(JSON.parse(new TextDecoder().decode(data)))
            this.SET_FILTERED_OFFERS(data)
            EventBus.$emit(FILTER_EVENT)
        }
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        hotelsStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
        newSingleHotelSearch.call(this, null)
        hotelsStore.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    newSingleHotelSearch() {
        newSingleHotelSearch.call(this, null)
    }

    @Action
    async reload() {
        const promises = []
        if (this.hotelInfoRequest.hotelCode) promises.push(this.loadHotelInfo(this.hotelInfoRequest))
        if (this.city.id) promises.push(this.loadCity(this.city.id))
        await Promise.all(promises)
    }

    @Action
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await partialSearch.call(this, rq)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action
    stopSearch() {
        if (this.partialSearchResultsCTS) this.partialSearchResultsCTS.cancel()
        if (this.partialSearchTimeoutInstance) this.partialSearchTimeoutInstance.cancel()
    }

    @Action({rawError: true})
    async singleHotelSearch(rq) {
        this.START_SINGLE_HOTEL_SEARCH()
        newSingleHotelSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await singleHotelSearch.call(this, rq)
            // eslint-disable-next-line no-empty
        } catch (e) {
            console.error(e)
        } finally {
            this.STOP_SINGLE_HOTEL_SEARCH()
        }
    }

    @Action
    stopSingleHotelSearch() {
        if (this.singleHotelSearchCTS) this.singleHotelSearchCTS.cancel()
        if (this.singleHotelSearchTimeoutInstance) this.singleHotelSearchTimeoutInstance.cancel()
    }

    @Action({rawError: true})
    async updateRoomOffers({supplierCode, cityCode, hotelCode}) {
        const hotelInfoRq = {supplierCode, cityCode, hotelCode}
        this.ADD_UPDATE_ROOMS_ACTIVE(hotelInfoRq)
        try {
            const hotelInfoRs = await appInstance.$api.hotelInfo.get(hotelInfoRq),
                hotelDescriptionInfo = hotelInfoRs.hotelDescriptionInfo,
                hotelId = hotelDescriptionInfo.localMappedHotelId || hotelDescriptionInfo.hotelId
            if (!hotelId) return
            const searchRequest = Object.assign({}, hotelsStore.searchRequest, {partialResponse: false, hotelId})
            const rs = await appInstance.$api.searchAccommodation.get(searchRequest)
            const payload = {...hotelInfoRq, ...{rooms: rs.offers[0].rooms}}
            const cachedOffers = await appInstance.$localForage.getItem('hotels')
            const hotelOffer = cachedOffers.find(
                offer =>
                    offer.supplierCode === supplierCode && offer.cityCode === cityCode && offer.hotelCode === hotelCode
            )
            hotelOffer.rooms = payload.rooms
            hotelOffer.updatedRooms = true
            appInstance.$localForage.setItem('hotels', cachedOffers)
            console.time('load')
            hotelsWorker.postMessage({action: 'refresh', offers: cachedOffers})
            this.UPDATE_ROOMS(payload)
            // eslint-disable-next-line no-empty
        } catch (e) {
            console.error(e)
        } finally {
            this.REMOVE_UPDATE_ROOMS_ACTIVE(hotelInfoRq)
        }
    }

    @Action
    load(offers = []) {
        //const offers = new TextEncoder().encode(JSON.stringify(hotelsStore.searchResponse.offers)).buffer
        //hotelsWorker.postMessage({action: 'load', offers}, [offers])
        console.time('load')
        hotelsWorker.postMessage({action: 'load', offers})
    }

    @Action
    filter() {
        this.START_FILTER()
        console.time('filter')
        hotelsWorker.postMessage({action: 'filter', filters: hotelsStore.filters, sortKey: hotelsStore.sortFnName})
    }

    @Action
    sort() {
        this.START_FILTER()
        console.time('filter')
        hotelsWorker.postMessage({action: 'sort', sortKey: hotelsStore.sortFnName})
    }

    get filterActive() {
        return this.filterActiveCount > 0
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get singleHotelSearchActive() {
        return this.singleHotelSearchActiveCount > 0
    }

    get updateRoomsActive() {
        return ({supplierCode, cityCode, hotelCode}) =>
            this.updateActiveOffers.findIndex(item => matches(item, {supplierCode, cityCode, hotelCode})) !== -1
    }

    get needUpdateRooms() {
        return ({supplierCode, cityCode, hotelCode, updatedRooms}) =>
            supplierCode === 'tbo' && !updatedRooms && !this.updateRoomsActive({supplierCode, cityCode, hotelCode})
    }

    get searchRequestTouristsJSON() {
        return searchRequest => {
            return searchRequest.rooms.map(room => {
                const groups = room.match(/^adults:(?<adults>\d)(,childrenAges:(?<childrenAges>(\d{1,2},?)+)$)?/).groups
                return {
                    adults: parseInt(groups.adults),
                    childrenAges: groups.childrenAges ? groups.childrenAges.split(',').map(age => parseInt(age)) : [],
                }
            })
        }
    }

    get searchRequestTouristsTotal() {
        return searchRequest =>
            this.searchRequestTouristsJSON(searchRequest).reduce(
                (total, tourists) => total + tourists.adults + tourists.childrenAges.length,
                0
            )
    }

    get roomQueryString() {
        return ({adults, childrenAges}) => {
            let str = `adults:${adults}`
            if (childrenAges.length) {
                str += `,childrenAges:${childrenAges.join(',')}`
            }
            return str
        }
    }

    get hotelsPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, hotelId, hotelCode, ...query} = searchRequest
            return {name: 'hotels', query}
        }
    }

    get hotelPageLink() {
        return (offer, searchRequest) => {
            let {supplierCode, cityCode, hotelCode} = offer
            supplierCode = encodeSupplierCode(supplierCode)
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, hotelId, distance, latitude, longitude, ...query} = searchRequest
            return {name: 'hotel', query: {...query, supplierCode, cityCode, hotelCode}}
        }
    }

    get ownProduct() {
        return offer => runtimeStore.ownProduct(offer.supplierCode)
    }

    get minPriceRoomOffer() {
        return ({rooms}) => {
            if (!rooms[0].price) {
                const priceKey = runtimeStore.config.packagesNotDeltaPrice ? 'notDeltaPrice' : 'deltaPrice'
                return rooms.reduce((a, b) => (a[priceKey].amount < b[priceKey].amount ? a : b), rooms[0])
            }
            const searchRoomsCount = hotelsStore.searchRequest.rooms.length,
                currency = persistentStore.currency
            if (searchRoomsCount === 1)
                return rooms.reduce((a, b) => (a.price.amount < b.price.amount ? a : b), rooms[0])
            const sumOffer = {price: {amount: 0, currency}}
            for (let rph = 1; rph <= searchRoomsCount; rph++) {
                const minPrice = rooms
                    .filter(room => room.groupedOffers.findIndex(groupedOffer => groupedOffer.rph === rph) !== -1)
                    .reduce((a, b) => (convertPrice(a.price).amount < convertPrice(b.price).amount ? a : b), {
                        price: {amount: Infinity, currency},
                    }).price
                if (minPrice.amount !== Infinity) sumOffer.price.amount += convertPrice(minPrice).amount
                if (minPrice.taxesAndFeesExcluded) {
                    if (!sumOffer.price.taxesAndFeesExcluded) {
                        sumOffer.price.taxesAndFeesExcluded = minPrice.taxesAndFeesExcluded
                    } else {
                        sumOffer.price.taxesAndFeesExcluded = [
                            ...sumOffer.price.taxesAndFeesExcluded,
                            ...minPrice.taxesAndFeesExcluded,
                        ]
                    }
                }
                if (minPrice.taxesAndFeesIncludedSum) {
                    if (!sumOffer.price.taxesAndFeesIncludedSum) {
                        sumOffer.price.taxesAndFeesIncludedSum = {...minPrice.taxesAndFeesIncludedSum}
                    } else {
                        sumOffer.price.taxesAndFeesIncludedSum = sumPrice([
                            sumOffer.price.taxesAndFeesIncludedSum,
                            minPrice.taxesAndFeesIncludedSum,
                        ])
                    }
                }
            }
            return sumOffer
        }
    }
}
