export default function(offers, filters) {
    // DEFINE FILTER FUNCTIONS
    // eslint-disable-next-line no-unused-vars
    const priceFilter = function(product, values) {
        const price = product.price || product.deltaPrice
        if (price) {
            return price.amount >= values[0] && price.amount <= values[1]
        }
        return true
    }

    // eslint-disable-next-line no-unused-vars
    const durationFilter = function(product, values) {
        if (values) {
            return product.sumDuration >= values[0] && product.sumDuration <= values[1]
        }
        return true
    }

    // eslint-disable-next-line no-unused-vars
    const airlineFilter = function(offer, values, key) {
        return offer.itinerary.some(itinerary => {
            return itinerary.segments.some(({marketingAirlineName}) => values.includes(marketingAirlineName))
        })
    }

    // eslint-disable-next-line no-unused-vars
    const brandedFareNameFilter = function(offer, values) {
        return offer.itinerary.some(itinerary => {
            return itinerary.segments.some(({brandedFareName}) => values.includes(brandedFareName))
        })
    }

    // eslint-disable-next-line no-unused-vars
    const classFilter = function(offer, values) {
        return offer.itinerary.some(itinerary => {
            return itinerary.segments.some(({class: flightClass}) => values.includes(flightClass.classType))
        })
    }

    // eslint-disable-next-line no-unused-vars
    const refundableFilter = function(offer, values) {
        let val = 'true'
        if (offer.refundable === false) {
            val = 'false'
        }
        if (offer.refundable === undefined) {
            val = 'undefined'
        }
        return values.includes(val)
    }

    // eslint-disable-next-line no-unused-vars
    const stopsFilter = function(offer, values) {
        return offer.itinerary.some(itinerary => {
            return values.includes(itinerary.stopsCount) || values.includes(itinerary.stopsCount.toString())
        })
    }

    // eslint-disable-next-line no-unused-vars
    const flightNumberFilter = function(offer, values) {
        return offer.itinerary.some(itinerary => {
            return itinerary.segments.some(({flightNumber}) => {
                const input = values
                    .split(' ')
                    .join('')
                    .toLowerCase()
                if (values && values.length) {
                    return input.includes(flightNumber)
                }
                return true
            })
        })
    }

    // eslint-disable-next-line no-unused-vars
    const startTimeFilter = function(offer, values) {
        const date = offer.itinerary[0].segments[0].departure.date
        return isTimeInInterval(date, values)
    }

    // eslint-disable-next-line no-unused-vars
    const endTimeFilter = function(offer, values) {
        const date =
            offer.itinerary[offer.itinerary.length - 1].segments[
                offer.itinerary[offer.itinerary.length - 1].segments.length - 1
            ].arrival.date
        return isTimeInInterval(date, values)
    }

    const isTimeInInterval = function(date, values) {
        date = date.replace(' ', 'T')
        const parsed = new Date(date)
        const startDate = new Date(date)
        const endDate = new Date(date)
        const startMinutes = values[0] % 60
        const startHours = (values[0] - startMinutes) / 60
        const endMinutes = values[1] % 60
        const endHours = (values[1] - startMinutes) / 60
        startDate.setHours(startHours)
        startDate.setMinutes(startMinutes)
        endDate.setHours(endHours)
        endDate.setMinutes(endMinutes)
        return parsed.getTime() >= startDate.getTime() && parsed.getTime() <= endDate.getTime()
    }

    // eslint-disable-next-line no-unused-vars
    const applySingleFilter = function(offer, filter, values, key = null) {
        if (values.length > 0) {
            const otherIndex = values.indexOf('other')
            if (offer[filter]) {
                const value = key || offer[filter]
                if (Array.isArray(value)) {
                    return value.some(el => {
                        return values.includes(el)
                    })
                } else {
                    let index = values.indexOf(value)
                    if (index < 0) {
                        index = values.indexOf(String(value))
                    }
                    return index >= 0
                }
            }
            return otherIndex >= 0
        }
        return true
    }

    //END DEFINE FILTER FUNCTIONS
    //START FILTER PROCESS
    return offers.filter(offer => {
        const result = []
        Object.keys(filters).forEach(key => {
            const functName = `${key}Filter`
            if (filters[key] && filters[key].length) {
                const resultVal = eval(functName)(offer, filters[key], key)
                result.push(resultVal)
            } else {
                result.push(true)
            }
        })
        return result.every(el => el)
    })
}
