export default function({redirect, route, $cookies, $config}) {
    if ($config.account.disableB2BRegistration && route.name === 'b2b-register') {
        return redirect({name: 'b2b-auth'})
    }
    if (!$config.onlyRegistered) return
    const role = $cookies.get('role'),
        authPageName = $config.onlyRegistered === 'client' ? 'authorization' : 'b2b-auth',
        regPageName = $config.onlyRegistered === 'client' ? 'register' : 'b2b-register',
        signInPages = [authPageName, regPageName, 'restore-password']
    if ([authPageName, regPageName, 'restore-password'].includes(route.name) && role) {
        return redirect({name: 'home'})
    } else if (
        (!role && !signInPages.includes(route.name)) ||
        ($config.onlyRegistered === 'corporate' && route.name === regPageName)
    ) {
        return redirect({name: authPageName})
    }
}
