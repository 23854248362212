import {Module, VuexModule, MutationAction, Action, Mutation} from 'vuex-module-decorators'
import {appInstance} from '@/utils/app-accessor'
import {homePageSettings, hotelSettings, layoutSettings} from '@/utils/cms/cms-blank-states'
import {CHANGE_LOCALE_EVENT, EventBus} from '@/utils/event-bus'

@Module({name: 'cms', stateFactory: true, namespaced: true})
export default class CmsStore extends VuexModule {
    popularDestinations = []
    layoutSettings = layoutSettings()
    customTranslations = {}
    homePageSettings = homePageSettings()
    hotelSettings = hotelSettings()
    hotelSettingsLoaded = false
    blogItems = []
    topTours = []

    @Mutation
    SET_CUSTOM_TRANSLATIONS(customTranslations) {
        this.customTranslations = customTranslations
    }

    @MutationAction({mutate: ['popularDestinations']})
    async loadPopularDestinations() {
        try {
            const destinations = await appInstance.$api.popularDestinations.get()
            const promises = destinations.map(destination =>
                appInstance.$api.locations.get({id: destination.id, limitCities: 0})
            )
            const data = await Promise.all(promises)
            const popularDestinations = data
                .map((rs, index) => {
                    if (!rs.cities.length) return null
                    const location = rs.cities[0]
                    Object.assign(location, destinations[index])
                    return location
                })
                .filter(Boolean)
            return {popularDestinations}
        } catch (e) {
            return {popularDestinations: []}
        }
    }

    @MutationAction({mutate: ['layoutSettings']})
    async loadLayoutSettings() {
        try {
            const layoutSettings = await appInstance.$api.layoutSettings.get()
            return {layoutSettings}
        } catch (e) {
            return {layoutSettings: layoutSettings()}
        }
    }

    @MutationAction({mutate: ['homePageSettings']})
    async loadHomePageSettings() {
        try {
            const homePageSettings = await appInstance.$api.homePageSettings.get()
            return {homePageSettings}
        } catch (e) {
            return {homePageSettings: homePageSettings()}
        }
    }

    @MutationAction({mutate: ['hotelSettings', 'hotelSettingsLoaded']})
    async loadHotelSettings() {
        try {
            const hotelSettings = await appInstance.$api.hotelSettings.get()
            return {hotelSettings, hotelSettingsLoaded: true}
        } catch (e) {
            return {hotelSettings: hotelSettings(), hotelSettingsLoaded: false}
        }
    }

    @MutationAction({mutate: ['blogItems']})
    async loadBlogItems() {
        try {
            const blogItems = await appInstance.$api.blog.get()
            return {blogItems}
        } catch (e) {
            return {blogItems: []}
        }
    }

    @MutationAction({mutate: ['topTours']})
    async loadTopTours() {
        try {
            const topTours = await appInstance.$api.topTours.get()
            return {topTours}
        } catch (e) {
            return {topTours: []}
        }
    }

    @Action
    async serverInit() {
        await Promise.all([this.loadLayoutSettings()])
    }

    @Action
    clientInit() {
        EventBus.$on(CHANGE_LOCALE_EVENT, this.loadLayoutSettings)
    }
}
