export default function(offers, filters) {
    const getDistanceKey = function(distance) {
        const LOCATION_NEAREST_START = 0,
            LOCATION_NEAREST_END = 0.99,
            LOCATION_NEAR_START = 1.0,
            LOCATION_NEAR_END = 1.99,
            LOCATION_NORMAL_START = 2.0,
            LOCATION_NORMAL_END = 2.99
        if (distance >= LOCATION_NEAREST_START && distance <= LOCATION_NEAREST_END) {
            return 'a_nearest'
        }
        if (distance >= LOCATION_NEAR_START && distance <= LOCATION_NEAR_END) {
            return 'b_near'
        }
        if (distance >= LOCATION_NORMAL_START && distance <= LOCATION_NORMAL_END) {
            return 'c_normal'
        }
        return 'd_far'
    }

    // DEFINE FILTER FUNCTIONS
    const fn = {}
    fn.price = function(offer, values) {
        const rooms = offer.rooms.filter(room => {
            const price = room.price || room.deltaPrice
            return price.amount >= values[0] && price.amount <= values[1]
        })
        return rooms.length > 0
    }

    fn.category = function(offer, values, filter) {
        return applySingleFilter(offer, filter, values)
    }

    fn.tripadvisorRating = function(offer, values, filter) {
        return !values.length || (offer[filter] && values.indexOf(offer[filter].rating) !== -1)
    }

    fn.location = function(offer, values, filter) {
        return applySingleFilter(offer, filter, values)
    }

    fn.district = function(offer, values, filter) {
        return applySingleFilter(offer, filter, values)
    }

    fn.type = function(offer, values, filter) {
        return applySingleFilter(offer, filter, values)
    }

    fn.distance = function(offer, values, filter) {
        const key = getDistanceKey(offer.distance)
        return applySingleFilter(offer, filter, values, key)
    }

    fn.chain = function(offer, values, filter) {
        return applySingleFilter(offer, filter, values)
    }

    fn.services = function(offer, values, filter) {
        return applyMultipleFilter(offer, filter, values)
    }

    fn.classifications = function(offer, values, filter) {
        return applyMultipleFilter(offer, filter, values)
    }

    fn.supplierCode = function(offer, values, filter) {
        if (!values.length) return true
        return values.every(
            val => (val === 'own' && offer[filter].indexOf('company.') === 0) || offer[filter].indexOf(val) !== -1
        )
    }

    const applySingleFilter = function(offer, filter, values, key = null) {
        if (!values.length) return true
        const otherIndex = values.indexOf('other')
        if (offer[filter] || offer[filter] == 0) {
            const value = key || offer[filter]
            let index = values.indexOf(value)
            if (index < 0) {
                index = values.indexOf(String(value))
            }
            return index !== -1
        }
        return otherIndex !== -1
    }

    const applyMultipleFilter = function(offer, filter, values) {
        if (!values.length) return true
        return values.every(val => offer[filter].indexOf(val) !== -1)
    }

    fn.availability = function(offer, values) {
        if (values.length) {
            const rooms = offer.rooms.filter(room => {
                const key = room.available ? 'available' : 'on_request'
                const index = values.indexOf(key)
                return index >= 0
            })
            return rooms.length > 0
        }
        return true
    }

    fn.shared = function(offer, values) {
        if (values.length) {
            const rooms = offer.rooms.filter(room => {
                const index = values.indexOf(room.shared)
                return index >= 0
            })
            return rooms.length > 0
        }
        return true
    }

    fn.roomTypes = function(offer, values) {
        if (values.length) {
            const rooms = offer.rooms.filter(room => {
                const index = values.indexOf(room.name)
                return index >= 0
            })
            return rooms.length > 0
        }
        return true
    }

    fn.mealTypes = function(offer, values) {
        if (values.length) {
            const rooms = offer.rooms.filter(({mealTypeStandardCode}) => {
                return values.includes(mealTypeStandardCode)
            })
            return rooms.length > 0
        }
        return true
    }

    fn.name = function(offer, values) {
        if (values) {
            return offer.name.toLowerCase().includes(values.toLowerCase())
        }
        return true
    }
    //END DEFINE FILTER FUNCTIONS

    filters.tripadvisorRating = filters.tripadvisorRating.map(rating => +rating)

    //START FILTER PROCESS
    return Object.keys(filters).reduce((filteredOffers, key) => {
        if (Array.isArray(filters[key]) && key !== 'price' && !filters[key].length) return filteredOffers
        return filteredOffers.filter(offer => fn[key](offer, filters[key], key))
    }, offers)
}
