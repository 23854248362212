export default class User {
    constructor(person) {
        this.person = person
    }

    get isEmployee() {
        return this.person && this.person.role === 'corp.employee'
    }

    get passport() {
        return this.person.passport
    }

    get isCorporate() {
        return false
    }

    get canSelectTourist() {
        return false
    }

    get canBeTourlead() {
        return true
    }

    get companyId() {
        return this.person.companyId
    }
}
