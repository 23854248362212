import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {packagesStore, persistentStore} from '@/store'
import {
    EventBus,
    FILTER_EVENT,
    SEARCH_EVENT,
    PACKAGE_FLIGHT_SELECTED,
    RESET,
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
} from '@/utils/event-bus'
import {searchRequest, searchResponse} from '@/utils/packages/packages-blank-states'
import {appInstance} from '@/utils/app-accessor'
import flightsFilter from '@/filters/flightsFilter'
import flightsSort from '@/filters/flightsSort'
import {addSeconds} from 'date-fns'
import hotelsSort from '@/filters/hotelsSort'
import hotelsFilter from '@/filters/hotelsFilter'
import Vue from 'vue'
import {axiosInstance} from '@/utils/axios-accessor'
import {matches} from '@/utils/helpers'
import {convertPrice} from '@/utils/filters'

//TODO Refactoring partial search with all products
async function search(rq) {
    const cancelTokenSource = axiosInstance.CancelToken.source()
    this.SET_SEARCH_CTS(cancelTokenSource)
    const searchResponse = await appInstance.$api.searchDynamicPackages.get(rq, cancelTokenSource.token)
    if (!searchResponse) return
    packagesStore.SET_SEARCH_RESPONSE(searchResponse)
    if (searchResponse.offers.flights.length && searchResponse.offers.hotels.length) {
        packagesStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), CONFIG.search.offersLifetime))
        packagesStore.SET_SELECTED_HOTEL_KEY(
            searchResponse.selectedOffersInfo.find(el => el.productType === 'ACCOMMODATION').offerKey
        )
        packagesStore.SET_SELECTED_FLIGHT_KEY(
            searchResponse.selectedOffersInfo.find(el => el.productType === 'FLIGHT').offerKey
        )
    }

    // packagesStore.SET_FLIGHT_FILTERS(rs.filters.flights)
    packagesStore.SET_FLIGHT_FILTER({
        key: 'duration',
        value: searchResponse.filters.flights.duration,
    })

    //SETTING NEW VALUES FOR PRICE FILTERS IF THEY ARE NOT SET OR NOT SELECTED YET
    if (searchResponse.offers.flights.length && searchResponse.offers.hotels.length) {
        const initialPrice = packagesStore.searchResponse.filters.hotels.price,
            priceFilterValue = packagesStore.hotelsFilters.price,
            newPriceFilterValue = priceFilterValue.slice()
        if (initialPrice[0] === priceFilterValue[0] || priceFilterValue[0] === 0) {
            newPriceFilterValue[0] = searchResponse.filters.hotels.price[0]
        }
        if (initialPrice[1] === priceFilterValue[1] || priceFilterValue[1] === Infinity || !newPriceFilterValue[1]) {
            newPriceFilterValue[1] = searchResponse.filters.hotels.price[1]
        }
        if (priceFilterValue[0] !== newPriceFilterValue[0] || priceFilterValue[1] !== newPriceFilterValue[1]) {
            packagesStore.SET_HOTELS_FILTER({key: 'price', value: newPriceFilterValue})
        }
        const initialPriceF = packagesStore.searchResponse.filters.flights.price,
            priceFilterValueF = packagesStore.flightFilters.price,
            newPriceFilterValueF = priceFilterValueF.slice()
        if (initialPriceF[0] === priceFilterValueF[0] || priceFilterValueF[0] === 0) {
            newPriceFilterValueF[0] = searchResponse.filters.flights.price[0]
        }
        if (
            initialPriceF[1] === priceFilterValueF[1] ||
            priceFilterValueF[1] === Infinity ||
            !newPriceFilterValueF[1]
        ) {
            newPriceFilterValueF[1] = searchResponse.filters.flights.price[1]
        }
        if (priceFilterValueF[0] !== newPriceFilterValueF[0] || priceFilterValueF[1] !== newPriceFilterValueF[1]) {
            packagesStore.SET_FLIGHT_FILTER({key: 'price', value: newPriceFilterValueF})
        }
    }
    await Promise.all([this.filterFlights(), this.filterHotels()])
    EventBus.$emit(PACKAGE_FLIGHT_SELECTED)
}

function newSearch(rq) {
    this.stopSearch()
    this.SET_FLIGHT_OFFERS([])
    this.SET_HOTEL_OFFERS([])
    packagesStore.SET_SEARCH_RESPONSE(searchResponse())
    packagesStore.NEW_SEARCH(rq)
}

function sort({offers, sortKey, sortFn, setOffersMutation}) {
    appInstance.$pool
        .exec(sortFn, [offers, sortKey])
        .then(sortedOffers => {
            setOffersMutation.call(this, sortedOffers)
            EventBus.$emit(FILTER_EVENT)
        })
        .catch(function(err) {
            console.error(err)
        })
        .then(function() {
            appInstance.$pool.terminate() // terminate all workers when done
        })
}

function filter({offers, filters, filterFn, sortAction}) {
    appInstance.$pool
        .exec(filterFn, [offers, filters])
        .then(offers => {
            sortAction.call(this, offers)
        })
        .catch(function(err) {
            console.error(err)
        })
        .then(function() {
            appInstance.$pool.terminate() // terminate all workers when done
        })
}

@Module({name: 'packagesRuntime', stateFactory: true, namespaced: true})
export default class PackagesRuntimeStore extends VuexModule {
    arrivalPoint = {}
    departurePoint = {}

    updateActiveOffers = []
    searchActiveCount = 0
    searchCTS = null
    flightOffers = []
    hotelOffers = []

    @Mutation
    SET_ARRIVAL_POINT(val) {
        this.arrivalPoint = val
    }

    @Mutation
    SET_DEPARTURE_POINT(val) {
        this.departurePoint = val
    }

    @Mutation
    SET_FLIGHT_OFFERS(val) {
        this.flightOffers = val
    }

    @Mutation
    SET_HOTEL_OFFERS(val) {
        this.hotelOffers = val
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    SET_SEARCH_CTS(cancelTokenSource) {
        this.searchCTS = cancelTokenSource
    }

    @Mutation
    ADD_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode}) {
        this.updateActiveOffers.push({supplierCode, cityCode, hotelCode})
    }

    @Mutation
    REMOVE_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode}) {
        this.updateActiveOffers = this.updateActiveOffers.filter(
            item => !matches(item, {supplierCode, cityCode, hotelCode})
        )
    }

    @Mutation
    UPDATE_ROOMS({supplierCode, cityCode, hotelCode, rooms}) {
        const hotel = this.hotelOffers.find(
            hotel => hotel.supplierCode === supplierCode && hotel.cityCode === cityCode && hotel.hotelCode === hotelCode
        )
        hotel.rooms = rooms
        Vue.set(hotel, 'updatedRooms', true)
    }

    @MutationAction({mutate: ['arrivalPoint', 'departurePoint']})
    async loadPoints({departureCityId, arrivalCityId}) {
        try {
            const points = await Promise.all([
                appInstance.$api.locations.get({id: departureCityId, limitCities: 1}),
                appInstance.$api.locations.get({id: arrivalCityId, limitCities: 1}),
            ])
            return {
                departurePoint: points[0].cities[0],
                arrivalPoint: points[1].cities[0],
            }
        } catch (e) {
            return {
                departurePoint: null,
                arrivalPoint: null,
            }
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        packagesStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    async reload() {
        if (this.arrivalPoint.id && this.departurePoint.id)
            await this.loadPoints({departureCityId: this.departurePoint.id, arrivalCityId: this.arrivalPoint.id})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    stopSearch() {
        if (this.searchCTS) this.searchCTS.cancel()
        this.SET_SEARCH_CTS(null)
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await search.call(this, rq)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }

    /**
     * For TBO supplier - hotel room offers refresh
     * @param supplierCode
     * @param cityCode
     * @param hotelCode
     * @param offerId
     * @returns {Promise<void>}
     */
    @Action({rawError: true})
    async updatePackageOffers({supplierCode, cityCode, hotelCode, offerId}) {
        try {
            this.ADD_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode})
            const rs = await appInstance.$api.updatePackageOffers.put({
                offersId: {
                    type: 'ACCOMMODATION',
                    offerId,
                },
                offerKey: packagesStore.searchResponse.offerKey,
            })
            const rooms = rs.offers.hotels.find(
                hotel =>
                    hotel.supplierCode === supplierCode && hotel.cityCode === cityCode && hotel.hotelCode === hotelCode
            ).rooms
            packagesStore.UPDATE_ROOMS({supplierCode, cityCode, hotelCode, offerId, rooms})
            this.UPDATE_ROOMS({supplierCode, cityCode, hotelCode, offerId, rooms})
        } catch (e) {
            return e
        } finally {
            this.REMOVE_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode})
        }
    }

    @Action
    async filterFlights() {
        filter.call(this, {
            filters: packagesStore.flightFilters,
            offers: packagesStore.searchResponse.offers.flights,
            filterFn: flightsFilter,
            sortAction: this.sortFlights,
        })
    }

    @Action
    async filterHotels() {
        filter.call(this, {
            filters: packagesStore.hotelsFilters,
            offers: packagesStore.searchResponse.offers.hotels,
            filterFn: hotelsFilter,
            sortAction: this.sortHotels,
        })
    }

    @Action
    sortHotels(offers) {
        sort.call(this, {
            offers,
            sortKey: packagesStore.hotelSortKey,
            sortFn: hotelsSort,
            setOffersMutation: this.SET_HOTEL_OFFERS,
        })
    }

    @Action
    sortFlights(offers) {
        sort.call(this, {
            offers,
            sortKey: packagesStore.flightSortKey,
            sortFn: flightsSort,
            setOffersMutation: this.SET_FLIGHT_OFFERS,
        })
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {convertToCurrency, ...query} = searchRequest
            return {name: 'packages', query}
        }
    }

    get totalPrice() {
        return (room, flight, price) => ({
            amount:
                convertPrice(price).amount +
                convertPrice(room.deltaPrice).amount +
                convertPrice(flight.deltaPrice).amount,
            currency: persistentStore.currency,
        })
    }

    get updateRoomsActive() {
        return ({supplierCode, cityCode, hotelCode}) =>
            this.updateActiveOffers.findIndex(item => matches(item, {supplierCode, cityCode, hotelCode})) !== -1
    }
}
