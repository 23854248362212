import {addDays, format} from 'date-fns'

export const filters = () => ({
        price: [0, Infinity],
        transferType: [],
        langCodes: [],
        name: null,
    }),
    searchRequest = () => ({
        convertToCurrency: null,
        departurePlace: null,
        departureCityId: null,
        departureCountryId: null,
        //departureHotelCode: null,
        arrivalPlace: null,
        arrivalCityId: null,
        arrivalCountryId: null,
        //arrivalHotelCode: null,
        adults: 1,
        childrenAges: [],
        dateTime: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        partialResponse: true,
    }),
    searchResponse = () => ({
        filters: filters(),
        product: [],
    })
