export default function(offers, filters) {
    // DEFINE FILTER FUNCTIONS
    // eslint-disable-next-line no-unused-vars
    const priceFilter = function(product, values) {
        product.offers = product.offers.filter(
            offer => offer.price.amount >= values[0] && offer.price.amount <= values[1]
        )
        return product.offers.length > 0
    }

    // eslint-disable-next-line no-unused-vars
    const transferTypeFilter = function(product, values, key) {
        const result = []
        product.offers.forEach(offer => {
            result.push(applySingleFilter(offer, key, values))
        })
        return result.some(value => value)
    }

    // eslint-disable-next-line no-unused-vars
    const langCodesFilter = function(product, values, key) {
        const result = []
        product.offers.forEach(offer => {
            result.push(applySingleFilter(offer, key, values))
        })
        // console.log(result)
        return result.some(value => value)
    }

    // eslint-disable-next-line no-unused-vars
    const nameFilter = function(product, values, key) {
        if (values) {
            return product.info.name.toLowerCase().includes(values.toLowerCase())
        }
        return true
    }

    const applySingleFilter = function(offer, filter, values, key = null) {
        if (values.length > 0) {
            const otherIndex = values.indexOf('other')
            if (offer[filter]) {
                const value = key || offer[filter]
                if (Array.isArray(value)) {
                    return value.some(el => {
                        return values.includes(el)
                    })
                } else {
                    let index = values.indexOf(value)
                    if (index < 0) {
                        index = values.indexOf(String(value))
                    }
                    return index >= 0
                }
            }
            return otherIndex >= 0
        }
        return true
    }

    //END DEFINE FILTER FUNCTIONS
    //START FILTER PROCESS
    return offers.filter(offer => {
        const result = []
        Object.keys(filters).forEach(key => {
            const functName = `${key}Filter`
            result.push(eval(functName)(offer, filters[key], key))
        })
        return result.every(el => el)
    })
}
