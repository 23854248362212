export const layoutSettings = () => ({
        logo: '/logo.png',
        socialLinks: [],
        footerMenu: [],
        script: null,
    }),
    homePageSettings = () => ({
        searchFormBackgrounds: {},
        topBlock: null,
        bottomBlock: null,
    }),
    hotelSettings = () => ({
        showContacts: false,
    })
