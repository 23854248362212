import {filters as flightFilters} from '@/utils/flights/flights-blank-states'
import {filters as hotelFilters} from '@/utils/hotels/hotels-blank-states'
import {addDays, format} from 'date-fns'

export const searchRequest = () => ({
        convertToCurrency: null,
        departureCityId: null,
        arrivalCityId: null,
        startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
        adults: 1,
        childrenAges: [],
    }),
    searchResponse = () => ({
        filters: {
            flights: flightFilters(),
            hotels: hotelFilters(),
        },
        offers: {
            hotels: [],
            flights: [],
        },
        selectedOffersInfo: [],
        clientPrice: {
            price: {
                amount: 0,
            },
        },
    })
