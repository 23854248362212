import {interopDefault} from '@/utils/helpers'

const HotelsPage = () => interopDefault(import('~/pages/search/HotelsPage'))
const HotelPage = () => interopDefault(import('~src/pages/info/hotelPage.src'))
const ComparisonPage = () => interopDefault(import('~/pages/comparison/ComparisonPage'))
const HotelBookingConfirmationPage = () => interopDefault(import('~/pages/booking/HotelBookingConfirmationPage'))
const HotelBookingPage = () => interopDefault(import('~/pages/hotels/HotelBookingPage'))
const SearchFormPage = () => interopDefault(import('~/pages/hotels/SearchFormPage'))

export default [
    {
        path: '/search/hotels',
        component: HotelsPage,
        name: 'hotels',
    },
    {
        path: '/hotel',
        component: HotelPage,
        name: 'hotel',
    },
    {
        path: '/hotels/compare',
        component: ComparisonPage,
        name: 'comparison',
    },
    {
        path: '/hotels/confirmation',
        component: HotelBookingConfirmationPage,
        name: 'hotelConfirmation',
    },
    {
        path: '/hotels/booking',
        component: HotelBookingPage,
        name: 'hotelBooking',
    },
    {
        path: '/hotels/search-form',
        component: SearchFormPage,
        name: 'searchForm',
    },
]
