export default function(offers, sortFnName) {
    const minPrice = offer =>
            Math.min.apply(
                null,
                offer.rooms.map(room => room.price.amount)
            ),
        minDeltaPrice = offer =>
            Math.min.apply(
                null,
                offer.rooms.map(room => room.deltaPrice.amount)
            ),
        sortFn = {
            priceAsc: (offer1, offer2) => minPrice(offer1) - minPrice(offer2),
            priceDesc: (offer1, offer2) => minPrice(offer2) - minPrice(offer1),
            priceDeltaAsc: (offer1, offer2) => minDeltaPrice(offer1) - minDeltaPrice(offer2),
            priceDeltaDesc: (offer1, offer2) => minDeltaPrice(offer2) - minDeltaPrice(offer1),
            name: (offer1, offer2) => {
                if (offer1.name < offer2.name) return -1
                if (offer1.name > offer2.name) return 1
                return 0
            },
            categoryAsc: (offer1, offer2) => offer1.category - offer2.category,
            categoryDesc: (offer1, offer2) => offer2.category - offer1.category,
        }
    return offers.concat().sort((offer1, offer2) => sortFn[sortFnName](offer1, offer2))
}
