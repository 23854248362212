import {addDays, format} from 'date-fns'

export const filters = () => ({
        price: [0, Infinity],
        category: [],
        location: [],
        type: [],
        district: [],
        distance: [],
        chain: [],
        services: [],
        classifications: [],
        shared: [],
        availability: [],
        roomTypes: [],
        mealTypes: [],
        specialOffer: [],
        tripadvisorRating: [],
        name: null,
        supplierCode: [],
    }),
    searchRequest = () => ({
        cityId: null,
        startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        endDate: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
        rooms: ['adults:2'],
        partialResponse: true,
        convertToCurrency: null,
    }),
    searchResponse = () => ({
        currency: null,
        filters: filters(),
        offers: [],
    }),
    hotelInfoRequest = () => ({
        hotelCode: null,
        cityCode: null,
        supplierCode: null,
    }),
    hotelInfoResponse = () => ({
        hotelDescriptionInfo: {
            mainImage: {
                url: null,
                title: null,
            },
            images: {
                image: [],
            },
            localMappedHotelId: null,
            hotelId: null,
            hotelCode: null,
            mealTypes: {
                mealType: [],
            },
        },
    })
