import {axiosInstance} from '~/utils/axios-accessor'

async function get(request) {
    return await axiosInstance.$get('/api/downloadOrders', {
        params: request,
        responseType: 'arraybuffer',
    })
}

export {get}
