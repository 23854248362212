import {Module, VuexModule, Action, Mutation, MutationAction} from 'vuex-module-decorators'
import {appInstance} from '~/utils/app-accessor'
import Vue from 'vue'
import {orderFilters, ordersResponse} from '@/utils/store-blank-states'
import {B2B_AUTH_EVENT, CHANGE_LOCALE_EVENT, EventBus, RESET} from '@/utils/event-bus'
import {authStore, persistentStore} from '@/store'

let appConfig
if (process.server) {
    appConfig = require('config')
}

@Module({name: 'runtime', stateFactory: true, namespaced: true})
export default class RuntimeStore extends VuexModule {
    loading = false
    authActive = false
    ordersResponse = ordersResponse()
    orderDetails = {}
    bookingActive = false
    conditionsLoading = {}
    persons = []
    clients = []
    companies = []
    touristBookingFields = {bookingFields: {}}
    exchangeRates = {}
    config = CONFIG.shared
    gpBaseUrl = CONFIG.gpBaseUrl
    countries = []
    clientsCount = 0

    @Mutation
    SET_CONFIG({config, gpBaseUrl}) {
        this.config = config
        this.gpBaseUrl = gpBaseUrl
    }

    @Mutation
    SET_ORDERS_RESPONSE(orders) {
        this.ordersResponse = orders
    }

    @Mutation
    SET_CONDITIONS_LOADING({offerKey, cts}) {
        Vue.set(this.conditionsLoading, offerKey, cts)
    }

    @Mutation
    SET_BOOKING_ACTIVE(active) {
        this.bookingActive = active
    }

    @Mutation
    SET_ORDER_DETAILS(data) {
        this.orderDetails = data
    }

    @Mutation
    SET_LOADING(loading) {
        this.loading = loading
    }

    @Mutation
    SET_PERSONS(persons) {
        this.persons = persons
    }

    @Mutation
    UPDATE_PERSON({index, val}) {
        Object.assign(this.persons[index], val)
    }

    @Mutation
    SET_CLIENTS(clients) {
        this.clients = clients
    }

    @Mutation
    SET_CLIENTS_COUNT(count) {
        this.clientsCount = count
    }

    @Mutation
    SET_COMPANIES(companies) {
        this.companies = companies
    }

    @Mutation
    UPDATE_ORDER({orderId, data}) {
        const index = this.ordersResponse.orders.findIndex(order => order.orderId === orderId)
        Vue.set(this.ordersResponse.orders, index, {...this.ordersResponse.orders[index], ...data})
    }

    @Mutation
    SET_AUTH_ACTIVE(active) {
        this.authActive = active
    }

    @Mutation
    RESET() {
        this.ordersResponse = ordersResponse()
        this.orderDetails = {}
        this.persons = []
    }

    @Mutation
    SET_DEFAULT_TOURIST_BOOKING_FIELDS() {
        this.touristBookingFields = {
            bookingFields: {
                BIRTHDATE: {status: 'MANDATORY', fieldFor: 'CHILD'},
                CITIZENSHIP: {status: 'DISABLED', fieldFor: 'ALL_TOURISTS'},
                EMAIL: {status: 'DISABLED', fieldFor: 'ALL_TOURISTS'},
                PASSPORT_EXPIRE_DATE: {status: 'DISABLED', fieldFor: 'ALL_TOURISTS'},
                PASSPORT_ISSUE_DATE: {status: 'DISABLED', fieldFor: 'ALL_TOURISTS'},
                PASSPORT_NUMBER: {status: 'DISABLED', fieldFor: 'ALL_TOURISTS'},
                PHONE: {status: 'DISABLED', fieldFor: 'ALL_TOURISTS'},
                PREFIX: {status: 'OPTIONAL', fieldFor: 'ALL_TOURISTS'},
            },
        }
    }

    @MutationAction({mutate: ['countries']})
    async loadCountries() {
        try {
            const rs = await appInstance.$api.locations.get({limitCountries: 300, orderBy: 'ALPHABET'})
            return {countries: rs.countries}
        } catch (e) {
            return {countries: []}
        }
    }

    @MutationAction({mutate: ['exchangeRates']})
    async loadExchangeRates(currencyCode) {
        try {
            const exchangeRates = await appInstance.$api.exchangeRates.get({
                currencyCode,
                precision: 10,
            })
            return {exchangeRates}
        } catch (e) {
            return {exchangeRates: {}}
        }
    }

    @MutationAction({mutate: ['touristBookingFields']})
    async loadTouristBookingFields(offerKey) {
        try {
            const touristBookingFields = await appInstance.$api.touristBookingFields.get({offerKey})
            //TODO It is workaround for set PASSPORT_TYPE
            const passportFieldKeys = [
                //'PASSPORT_ISSUE_COUNTRY',
                'PASSPORT_EXPIRE_DATE',
                'PASSPORT_ISSUE_DATE',
                'PASSPORT_NUMBER',
            ]
            const bookingFields = touristBookingFields.bookingFields
            touristBookingFields.bookingFields.PASSPORT_TYPE =
                passportFieldKeys.findIndex(
                    fieldKey => bookingFields[fieldKey] && bookingFields[fieldKey] === 'MANDATORY'
                ) !== -1
                    ? 'MANDATORY'
                    : 'OPTIONAL'
            return {touristBookingFields}
        } catch (e) {
            return {touristBookingFields: {bookingFields: {}}}
        }
    }

    @Action
    async loadOrders({rq, update}) {
        if (!update) {
            this.SET_LOADING(true)
        }
        try {
            const rs = await appInstance.$api.orders.get(rq)
            this.SET_ORDERS_RESPONSE(rs)
            return true
        } catch (e) {
            return false
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action({rawError: true})
    async loadOrder(rq) {
        this.SET_ORDER_DETAILS({})
        try {
            const rs = await appInstance.$api.orders.get(rq)
            this.SET_ORDER_DETAILS(rs.orders[0])
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    @Action
    async refreshOrder() {
        try {
            const {
                orders: [order],
            } = await appInstance.$api.orders.get({
                orderType: authStore.orderType,
                orderId: this.orderDetails.orderId,
            })
            this.SET_ORDER_DETAILS(order)
            // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    @Action
    async loadPersons(rq) {
        this.SET_LOADING(true)
        try {
            const [active, disabled] = await Promise.all([
                appInstance.$api.persons.get(Object.assign(rq, {active: true})),
                appInstance.$api.persons.get(Object.assign(rq, {active: false})),
            ])
            const persons = active.users.concat(disabled.users)
            this.SET_PERSONS(persons)
        } catch (e) {
            this.SET_PERSONS([])
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action
    async updatePerson(val) {
        this.SET_LOADING(true)
        const {id} = val
        delete val.id
        try {
            //await appInstance.$api.persons.put(id, val)
            const index = this.persons.findIndex(person => person.personId === id)
            this.UPDATE_PERSON({index, val})
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action
    async loadPrivateClients(rq) {
        this.SET_LOADING(true)
        try {
            if (rq.active === undefined) {
                const [active, disabled] = await Promise.all([
                    appInstance.$api.privateClients.get({...rq, active: true}),
                    appInstance.$api.privateClients.get({...rq, active: false}),
                ])
                this.SET_CLIENTS([...active.persons, ...disabled.persons])
            } else {
                const clients = await appInstance.$api.privateClients.get(rq)
                this.SET_CLIENTS_COUNT(clients.personsCount)
                this.SET_CLIENTS(clients.persons)
            }
        } catch (e) {
            this.SET_CLIENTS([])
            this.SET_CLIENTS_COUNT(0)
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action
    async loadCompanies(rq) {
        this.SET_LOADING(true)
        try {
            const rs = await appInstance.$api.companies.get(rq)
            this.SET_COMPANIES(rs.object)
        } catch (e) {
            this.SET_COMPANIES([])
        } finally {
            this.SET_LOADING(false)
        }
    }

    @Action
    async serverInit() {
        let config, gpBaseUrl
        if (appConfig) {
            config = appConfig.get('shared')
            gpBaseUrl = appConfig.get('gpBaseUrl')
        } else {
            config = CONFIG.shared
            gpBaseUrl = CONFIG.gpBaseUrl
        }
        this.SET_CONFIG({config, gpBaseUrl})
        persistentStore.SET_CURRENCY(config.currency.default)
        await Promise.all([this.loadExchangeRates(config.currency.default), this.loadCountries()])
    }

    @Action
    clientInit() {
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(RESET, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
    }

    @Action
    async reload() {
        await this.loadCountries()
    }

    @Action
    reset() {
        persistentStore.SET_TOURISTS([])
        persistentStore.SET_ORDER_FILTERS(orderFilters())
        persistentStore.SET_COMPANY_SETTINGS({})
        persistentStore.SET_CONDITIONS({})
        this.RESET()
    }

    get orderServiceStatus() {
        return status => {
            status = status.replace(/[\s.]/g, '_').toUpperCase()
            if (status === 'ERROR' && !authStore.isTO1) {
                return 'PENDING'
            } else {
                return status
            }
        }
    }

    get orderStatusColor() {
        return orderStatus => {
            switch (orderStatus) {
                case 'NEW':
                case 'IN_PROGRESS':
                    return 'warning'
                case 'COMPLETED':
                case 'COMPLETED_AND_CHECKED':
                    return 'success'
                case 'CANCELLED':
                    return 'error'
                default:
                    return 'error'
            }
        }
    }

    get orderServiceStatusColor() {
        return orderServiceStatus => {
            switch (orderServiceStatus) {
                case 'PENDING':
                case 'CONFIRMATION_PENDING':
                case 'CANCELLATION_PENDING':
                case 'ON_CONFIRM':
                case 'CONFIRMATION_PROCESSING':
                case 'MODIFICATION_PENDING':
                case 'PENDING_TICKETING':
                case 'PENDING_TICKETING_TO1L':
                case 'TICKET_ISSUING':
                case 'PRECONFIRMED':
                    return 'warning'
                case 'CONFIRMED':
                case 'ISSUED':
                case 'ESTIMATED':
                    return 'success'
                case 'CANCELED':
                case 'REJECTED':
                case 'ERROR':
                case 'CANCELLATION_REJECTED':
                case 'CANCELED_WITHOUT_FEE':
                    return 'error'
                default:
                    return orderServiceStatus === 'QUOTE' ? 'success' : 'warning'
            }
        }
    }

    get isConditionsLoading() {
        return offerKey => {
            if (persistentStore.conditions[offerKey]) return false
            return this.conditionsLoading[offerKey] === undefined || this.conditionsLoading[offerKey]
        }
    }

    get touristFromPerson() {
        return ({
            prefix,
            firstName,
            lastName,
            firstNameLocalized,
            lastNameLocalized,
            email,
            contactPhone,
            citizenshipId,
            personId,
            birthdate,
            role,
            passports,
        }) => {
            const tourist = {
                prefix,
                firstName: firstName || firstNameLocalized,
                lastName: lastName || lastNameLocalized,
                citizenshipId,
                email,
                phone: contactPhone,
                birthdate,
                type: 'adult',
                passport: {
                    type: null,
                    number: null,
                    issueDate: null,
                    expiryDate: null,
                },
                ...(role && {personId}),
            }
            if (passports && passports.length) {
                const {type, number, issueDate, validTill: expiryDate} = passports[0]
                Object.assign(tourist.passport, {type, number, issueDate, expiryDate})
            }
            return tourist
        }
    }

    get netPrice() {
        return ({amount, currency, originalAmount, originalCurrency, taxesAndFeesIncludedSum, serviceFee}) => ({
            amount:
                amount -
                (taxesAndFeesIncludedSum ? taxesAndFeesIncludedSum.amount : 0) -
                (serviceFee ? serviceFee.amount : 0),
            currency,
            ...(originalCurrency && {
                originalAmount:
                    originalAmount -
                    (taxesAndFeesIncludedSum && taxesAndFeesIncludedSum.originalAmount
                        ? taxesAndFeesIncludedSum.originalAmount
                        : 0) -
                    (serviceFee && serviceFee.originalAmount ? serviceFee.originalAmount : 0),
                originalCurrency,
            }),
        })
    }

    get isTouristBookingField() {
        return (fieldKey, statusKey, isMainTourist, isChild) => {
            const bookingFields = this.touristBookingFields.bookingFields
            if (!bookingFields[fieldKey]) return false
            const {status, fieldFor} = bookingFields[fieldKey]
            return (
                status === statusKey &&
                (fieldFor === 'ALL_TOURISTS' ||
                    (fieldFor === 'MAIN_TOURIST' && isMainTourist) ||
                    (fieldFor === 'CHILD' && isChild))
            )
        }
    }

    get isTouristBookingFieldDisabled() {
        return (fieldKey, isMainTourist, isChild) => {
            const bookingFields = this.touristBookingFields.bookingFields
            if (!bookingFields[fieldKey]) return false
            const {status, fieldFor} = bookingFields[fieldKey]
            return (
                this.isTouristBookingField(fieldKey, 'DISABLED', isMainTourist, isChild) ||
                (['OPTIONAL', 'MANDATORY'].includes(status) &&
                    ((fieldFor === 'MAIN_TOURIST' && !isMainTourist) || (fieldFor === 'CHILD' && !isChild)))
            )
        }
    }

    get ownProduct() {
        return supplierCode => supplierCode?.indexOf('company.') === 0
    }
}
