import {Module, VuexModule, Mutation, Action} from 'vuex-module-decorators'
import {appInstance} from '@/utils/app-accessor'
import {runtimeStore} from '@/utils/store-accessor'
import {conditions} from '~/api'
import Vue from 'vue'
import {axiosInstance} from '@/utils/axios-accessor'
import {orderFilters} from '@/utils/store-blank-states'

@Module({name: 'persistent', stateFactory: true, namespaced: true})
export default class PersistentStore extends VuexModule {
    orderFilters = orderFilters()
    ordersTableOptions = {
        itemsPerPage: CONFIG.account.ordersOnPage,
        page: 1,
    }
    clientsTableOptions = {
        itemsPerPage: 15,
        page: 1,
    }
    selectedOrderId = null
    tourists = []
    paymentMethods = []
    bookRequest = null
    bookResponse = [
        {
            orderId: null,
            refNumber: null,
            status: null,
        },
    ]
    paymentInitiateRequest = null
    paymentInitiateResponse = {}
    paymentCompleteRequest = null
    paymentCompleteResponse = {}
    conditions = {}
    companySettings = {}
    storageVersion = CONFIG.storageVersion
    currency = null
    isInternararyView = false

    @Mutation
    SET_TOURIST_PROP({index, prop, val}) {
        Vue.set(this.tourists, index, Object.assign({}, this.tourists[index], {[prop]: val}))
    }

    @Mutation
    SET_TOURIST_PASSPORT_PROP({index, prop, val}) {
        Vue.set(this.tourists[index].passport, prop, val)
    }

    @Mutation
    SET_TOURIST({index, val}) {
        Vue.set(this.tourists, index, val)
    }

    @Mutation
    SET_TOURISTS(tourists) {
        this.tourists = tourists
    }

    @Mutation
    SET_PAYMENT_METHODS(paymentMethods) {
        this.paymentMethods = paymentMethods
    }

    @Mutation
    SET_BOOK_REQUEST(bookRequest) {
        this.bookRequest = bookRequest
    }

    @Mutation
    SET_BOOK_RESPONSE(bookResponse) {
        this.bookResponse = bookResponse
    }

    @Mutation
    SET_PAYMENT_INITIATE_REQUEST(paymentInitiateRequest) {
        this.paymentInitiateRequest = paymentInitiateRequest
    }

    @Mutation
    SET_PAYMENT_INITIATE_RESPONSE(paymentInitiateResponse) {
        this.paymentInitiateResponse = paymentInitiateResponse
    }

    @Mutation
    SET_PAYMENT_COMPLETE_REQUEST(paymentCompleteRequest) {
        this.paymentCompleteRequest = paymentCompleteRequest
    }

    @Mutation
    SET_PAYMENT_COMPLETE_RESPONSE(paymentCompleteResponse) {
        this.paymentCompleteResponse = paymentCompleteResponse
    }

    @Mutation
    SET_CONDITIONS(conditions) {
        Vue.set(this.conditions, conditions.offerKey, conditions)
    }

    @Mutation
    REFRESH_CONDITIONS({offerKey, prepareBookResponse}) {
        //TODO Add new conditions from prepareBookResponse against return void
        if (!this.conditions[offerKey]) return
        this.conditions[offerKey].cancellationItems = prepareBookResponse.cancellations
    }

    @Mutation
    SET_ORDERS_TABLE_OPTIONS(val) {
        this.ordersTableOptions = val
    }

    @Mutation
    SET_CLIENTS_TABLE_OPTIONS(val) {
        this.clientsTableOptions = val
    }

    @Mutation
    SET_ORDER_FILTERS(val) {
        this.orderFilters = val
    }

    @Mutation
    SET_COMPANY_SETTINGS(settings) {
        this.companySettings = settings
    }

    @Mutation
    SET_CURRENCY(currency) {
        this.currency = currency
    }

    @Mutation
    SET_SELECTED_ORDER_ID(orderId) {
        this.selectedOrderId = orderId
    }

    @Mutation
    SET_INTERNERARY_VIEW(isInternararyView) {
        this.isInternararyView = isInternararyView
    }

    @Action({rawError: true})
    async getPaymentOptions(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        this.SET_PAYMENT_METHODS([])
        try {
            const rs = await appInstance.$api.paymentOptions.get(rq)
            if (rs.param && rs.param.paymentMethod && rs.param.paymentMethod.length) {
                this.SET_PAYMENT_METHODS(rs.param.paymentMethod)
            }
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    @Action({rawError: true})
    async book(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_BOOK_REQUEST(rq)
            const rs = await appInstance.$api.book.post(rq)
            this.SET_BOOK_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    @Action({rawError: true})
    async paymentInitiate(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_PAYMENT_INITIATE_REQUEST(rq)
            const rs = await appInstance.$api.onlinePaymentsInitiate.post(rq)
            this.SET_PAYMENT_INITIATE_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    @Action({rawError: true})
    async paymentComplete(rq) {
        this.SET_PAYMENT_COMPLETE_REQUEST(rq)
        const rs = await appInstance.$api.onlinePaymentsComplete.post(rq)
        this.SET_PAYMENT_COMPLETE_RESPONSE(rs)
        return rs
    }

    @Action
    async loadConditions(rq) {
        const offerKey = rq.offerKey
        try {
            const cts = axiosInstance.CancelToken.source()
            runtimeStore.SET_CONDITIONS_LOADING({offerKey, cts})
            const rs = await conditions.get(rq, cts.token)
            if (rs) {
                rs.offerKey = offerKey
                this.SET_CONDITIONS(rs)
            }
        } catch (e) {
            this.SET_CONDITIONS({offerKey, errors: ['Offer was expired']})
        } finally {
            runtimeStore.SET_CONDITIONS_LOADING({offerKey, cts: null})
        }
    }
}
