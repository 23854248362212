import Vue from 'vue'
import Router from 'vue-router'

import {interopDefault} from '~/utils/helpers'

const IndexPage = () => interopDefault(import('~src/pages/indexPage.src')),
    RegistrationPage = () => interopDefault(import('~/pages/account/RegistrationPage')),
    PaymentCompletePage = () => interopDefault(import('~/pages/booking/PaymentCompletePage')),
    PaymentRedirectPage = () => interopDefault(import('~/pages/booking/PaymentRedirectPage')),
    OrdersPage = () => interopDefault(import('~/pages/account/OrdersPage')),
    B2cAuthorizationPage = () => interopDefault(import('~/pages/account/B2cAuthorizationPage')),
    B2bAuthorizationPage = () => interopDefault(import('~/pages/account/B2bAuthorizationPage')),
    OrderDetailsPage = () => interopDefault(import('~/pages/account/OrderDetailsPage')),
    SettingsPage = () => interopDefault(import('~/pages/account/SettingsPage')),
    CompanyProfilePage = () => interopDefault(import('~/pages/account/CompanyProfilePage')),
    CompanyPersonsPage = () => interopDefault(import('~/pages/account/CompanyPersonsPage')),
    CompanyPersonPage = () => interopDefault(import('~/pages/account/CompanyPersonPage')),
    CompanyClientsPage = () => interopDefault(import('~/pages/account/CompanyClientsPage')),
    CompanyClientPage = () => interopDefault(import('~/pages/account/CompanyClientPage')),
    CompanyCorporateClientsPage = () => interopDefault(import('~/pages/account/CompanyCorporateClientsPage')),
    CompanyAgenciesPage = () => interopDefault(import('~/pages/account/CompanyAgenciesPage')),
    RestorePasswordPage = () => interopDefault(import('~/pages/account/RestorePasswordPage')),
    InfoPage = () => interopDefault(import('~/pages/cms/InfoPage')),
    AutologinPage = () => interopDefault(import('~/pages/AutologinPage')),
    ReportsPage = () => interopDefault(import('~/pages/account/ReportsPage')),
    CompanyRegistrationPage = () => interopDefault(import('~/pages/account/CompanyRegistrationPage')),
    InvoicePaymentCompletePage = () => interopDefault(import('~/pages/booking/InvoicePaymentCompletePage')),
    MembersPage = () => interopDefault(import('~/pages/account/MembersPage')),
    MemberPage = () => interopDefault(import('~/pages/account/MemberPage')),
    SupplierIndexPage = () => interopDefault(import('~/pages/supplier/SupplierPage')),
    SupplierProductsPage = () => interopDefault(import('~/pages/supplier/ProductsPage')),
    SupplierContractPage = () => interopDefault(import('~/pages/supplier/ContractPage')),
    ResortPage = () => interopDefault(import('~/pages/ResortPage'))

import hotels from '@/routes/hotels'
import transfers from '@/routes/transfers'
import activities from '@/routes/activities'
import admin from '@/routes/admin'
import flights from '@/routes/flights'
import packages from '@/routes/packages'
import tours from '@/routes/tours'
import extraServices from '@/routes/extraServices'

Vue.use(Router)

export function createRouter() {
    //TODO Set all route names to lowerCamelCase
    let routes = [
        {
            path: '/',
            component: IndexPage,
            name: 'home',
        },
        {
            path: '/register',
            component: RegistrationPage,
            name: 'register',
        },
        {
            path: '/payment-complete',
            component: PaymentCompletePage,
            name: 'paymentComplete',
        },
        {
            path: '/payment-redirect',
            component: PaymentRedirectPage,
            name: 'paymentRedirect',
        },
        {
            path: '/orders',
            component: OrdersPage,
            name: 'orders',
        },
        {
            path: '/authorization',
            component: B2cAuthorizationPage,
            name: 'authorization',
        },
        {
            path: '/b2b-auth',
            component: B2bAuthorizationPage,
            name: 'b2b-auth',
        },
        {
            path: '/b2b-register',
            component: CompanyRegistrationPage,
            name: 'b2b-register',
        },
        {
            path: '/orders/:id',
            component: OrderDetailsPage,
            name: 'order-details',
        },
        {
            path: '/settings',
            component: SettingsPage,
            name: 'user-settings',
        },
        {
            path: '/agency/profile',
            component: CompanyProfilePage,
            name: 'company-profile',
            alias: '/company/profile',
        },
        {
            path: '/agency/company/:id',
            component: CompanyProfilePage,
            name: 'company-client-profile',
            alias: '/company/:id',
        },
        {
            path: '/agency/users',
            component: CompanyPersonsPage,
            name: 'companyPersons',
            alias: '/company/users',
        },
        {
            path: '/agency/users/:id',
            component: CompanyPersonPage,
            name: 'companyPerson',
            alias: '/company/users/:id',
        },
        {
            path: '/agency/clients',
            component: CompanyClientsPage,
            name: 'companyClients',
            alias: '/company/clients',
        },
        {
            path: '/agency/clients/:id',
            component: CompanyClientPage,
            name: 'companyClient',
            alias: '/company/clients/:id',
        },
        {
            path: '/agency/corporate-clients',
            component: CompanyCorporateClientsPage,
            name: 'companyCorporateClients',
            alias: '/company/corporate-clients',
        },
        {
            path: '/agency/agencies',
            component: CompanyAgenciesPage,
            name: 'companyAgencies',
            alias: '/company/agencies',
        },
        {
            path: '/restore-password',
            component: RestorePasswordPage,
            name: 'restore-password',
        },
        {
            path: '/info/:link',
            component: InfoPage,
            name: 'infoPage',
        },
        {
            path: '/autologin',
            component: AutologinPage,
            name: 'autologin',
        },
        {
            path: '/reports',
            component: ReportsPage,
            name: 'reports',
        },
        {
            path: '/invoice-payment-complete',
            component: InvoicePaymentCompletePage,
            name: 'invoicePaymentComplete',
        },
        {
            path: '/members',
            component: MembersPage,
            name: 'members',
            alias: '/user/members',
        },
        {
            path: '/members/:id',
            component: MemberPage,
            name: 'member',
            alias: '/user/members/:id',
        },
        {
            path: '/supplier',
            component: SupplierIndexPage,
            name: 'supplier',
        },
        {
            path: '/supplier/products',
            component: SupplierProductsPage,
            name: 'supplierProducts',
        },
        {
            path: '/supplier/contract/:contractId',
            component: SupplierContractPage,
            name: 'supplierContract',
        },
        {
            path: '/resort/:link',
            component: ResortPage,
            name: 'resort',
        },
    ]
    routes = [
        ...routes,
        ...hotels,
        ...transfers,
        ...activities,
        ...flights,
        ...admin,
        ...packages,
        ...tours,
        ...extraServices,
    ]
    return new Router({
        mode: 'history',
        routes,
        scrollBehavior() {
            return {x: 0, y: 0}
        },
    })
}
