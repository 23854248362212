import Vue from 'vue'
export const EventBus = new Vue()
export const SEARCH_EVENT = 'SEARCH_EVENT'
export const FILTER_EVENT = 'FILTER_EVENT'
export const OFFER_ITEM_HOVER_EVENT = 'OFFER_ITEM_HOVER_EVENT'
export const OFFER_ITEM_LEAVE_EVENT = 'OFFER_ITEM_LEAVE_EVENT'
export const SHOW_ON_MAP_EVENT = 'SHOW_ON_MAP_EVENT'
export const LOGOUT_REDIRECT_EVENT = 'LOGOUT_REDIRECT_EVENT'
export const FOOTER_INTERSECT_EVENT = 'FOOTER_INTERSECT_EVENT'
export const SHOW_CONDITIONS_MODAL_EVENT = 'SHOW_CONDITIONS_MODAL_EVENT'
export const SHOW_HOTEL_CONDITIONS_INFO = 'SHOW_HOTEL_CONDITIONS_INFO'
export const SHOW_TRANSFER_CONDITIONS_INFO = 'SHOW_TRANSFER_CONDITIONS_INFO'
export const SHOW_FLIGHT_CONDITIONS_INFO = 'SHOW_FLIGHT_CONDITIONS_INFO'
export const SHOW_EXTRA_SERVICE_CONDITIONS_INFO = 'SHOW_EXTRA_SERVICE_CONDITIONS_INFO'
export const SHOW_ACTIVITY_CONDITIONS_INFO = 'SHOW_ACTIVITY_CONDITIONS_INFO'
export const SHOW_ROOM_INFO_MODAL_EVENT = 'SHOW_ROOM_INFO_MODAL_EVENT'
export const SHOW_MEAL_TYPE_INFO_EVENT = 'SHOW_MEAL_TYPE_INFO_EVENT'
export const FULL_SCREEN_MODE = 'FULL_SCREEN_MODE'
export const OFFER_EXPIRED_EVENT = 'OFFER_EXPIRED_EVENT'
export const CHANGE_TAB_MOBILE_EVENT = 'CHANGE_TAB_MOBILE_EVENT'
export const AUTH_EVENT = 'AUTH_EVENT'
export const SHOW_TRANSFER_INFO_MODAL = 'SHOW_TRANSFER_INFO_MODAL'
export const SHOW_ACTIVITIES_INFO_MODAL = 'SHOW_ACTIVITIES_INFO_MODAL'
export const SHOW_FLIGHT_PRICE_DETAIL = 'SHOW_FLIGHT_PRICE_DETAIL'
export const CHANGE_LOCALE_EVENT = 'CHANGE_LOCALE_EVENT'
export const PACKAGE_HOTEL_SELECTED = 'PACKAGE_HOTEL_SELECTED'
export const PACKAGE_FLIGHT_SELECTED = 'PACKAGE_FLIGHT_SELECTED'
export const RESET = 'RESET'
export const B2B_AUTH_EVENT = 'B2B_AUTH_EVENT'
export const SHOW_INVOICES_MODAL_EVENT = 'SHOW_INVOICES_MODAL_EVENT'
export const SHOW_MODIFY_MODAL_EVENT = 'SHOW_MODIFY_MODAL_EVENT'
export const CHANGE_PRICE_EVENT = 'CHANGE_PRICE_EVENT'
export const SHOW_EXTRA_SERVICE_INFO_MODAL = 'SHOW_EXTRA_SERVICE_INFO_MODAL'
export const ISSUE_INVOICES_MODAL_EVENT = 'ISSUE_INVOICES_MODAL_EVENT'
export const PAY_SERVICE_EVENT = 'PAY_SERVICE_EVENT'
export const PAY_ORDER_EVENT = 'PAY_ORDER_EVENT'
export const SHOW_PASSPORT_MODAL_EVENT = 'SHOW_PASSPORT_MODAL_EVENT'
export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS'
export const SHOW_LOYALTY_MODAL_EVENT = 'SHOW_LOYALTY_MODAL_EVENT'
export const SHOW_ADDITIONAL_FEES_MODAL = 'SHOW_ADDITIONAL_FEES_MODAL'
export const SAME_CONTACT_DATA_FOR_ALL = 'SAME_CONTACT_DATA_FOR_ALL'
