export const orderFilters = () => ({
        active: 'all',
        orderId: null,
        serviceName: null,
        travelerName: null,
        city: null,
        createdDateFrom: null,
        createdDateTo: null,
        serviceStartDateFrom: null,
        serviceStartDateTo: null,
        serviceEndDateFrom: null,
        serviceEndDateTo: null,
        serviceTypes: [],
        filterServices: false,
        processStatuses: [],
        customerPaymentStatuses: [],
        customerPenalty: [],
        supplierPaymentStatuses: [],
        supplierPenalty: [],
        paymentMethod: null,
        customerCompany: null,
    }),
    ordersResponse = () => ({
        orders: [],
    })
