const priceTransformer = salesTerm => {
    const {
        price: {amount, currency, commission},
        originalAmount,
        originalCurrency,
    } = salesTerm
    return {
        ...originalAmountReduce({amount, currency, originalAmount, originalCurrency}),
        ...(commission && commission.amount && {commission}),
    }
}

const originalAmountReduce = ({amount, currency, originalCurrency, originalAmount}) => ({
    amount,
    currency,
    ...(originalCurrency && originalCurrency !== currency && {originalAmount, originalCurrency}),
})

const clientSalesTermTransform = clientSalesTerm => {
    const {
        discount: {percent, type} = {percent: 0},
        promoCodeApplied: promoCode,
        priceBreakDowns,
        price,
    } = clientSalesTerm
    let taxesAndFeesIncludedSum,
        taxesAndFeesExcluded = []
    if (priceBreakDowns && priceBreakDowns.length) {
        const filteredPriceBreakDowns = priceBreakDowns.filter(({name}) => !!name)
        if (filteredPriceBreakDowns.length) {
            taxesAndFeesIncludedSum = sumPrice(filteredPriceBreakDowns.map(({price}) => price))
        }
    } else if (price.taxesAndFees && price.taxesAndFees.length) {
        const taxesAndFeesIncluded = price.taxesAndFees.filter(({taxIncluded}) => taxIncluded)
        if (taxesAndFeesIncluded.length) {
            taxesAndFeesIncludedSum = sumPrice(taxesAndFeesIncluded)
        }
        taxesAndFeesExcluded = price.taxesAndFees.filter(({taxIncluded}) => !taxIncluded)
    }
    return {
        ...priceTransformer(clientSalesTerm),
        discount: percent,
        ...(type === 'HIDDEN' && {hiddenDiscount: true}),
        ...(promoCode && {promoCode}),
        taxesAndFeesIncludedSum,
        ...(taxesAndFeesExcluded.length && {taxesAndFeesExcluded}),
        ...(typeof price.serviceFee === 'object' &&
            !!price.serviceFee.amount && {serviceFee: originalAmountReduce(price.serviceFee)}),
    }
}

const getClientSalesTerm = salesTerms => {
    return salesTerms.find(el => el.type === 'CLIENT')
}

export const sumPrice = prices => {
    const [{currency, originalCurrency}] = prices
    return prices.reduce(
        (total, {amount, originalAmount}) => {
            total.amount += amount
            if (originalAmount) total.originalAmount += originalAmount
            return total
        },
        {
            currency,
            amount: 0,
            ...(originalCurrency && originalCurrency !== currency && {originalAmount: 0, originalCurrency}),
        }
    )
}

export const salesTermsRsTransformer = salesTerms => {
    const supplierSalesTerm = salesTerms.find(el => el.type === 'SUPPLIER'),
        clientSalesTerm = getClientSalesTerm(salesTerms)
    return {
        price: clientSalesTermTransform(clientSalesTerm),
        ...(supplierSalesTerm && {supplierPrice: priceTransformer(supplierSalesTerm)}),
    }
}

export const salesTermPackagesRsTransformer = salesTermPackages => {
    const {
        deltaPrice: {amount, currency},
        notDeltaPrice,
    } = salesTermPackages.find(el => el.type === 'CLIENT')
    return {
        deltaPrice: {amount, currency},
        ...(notDeltaPrice && {notDeltaPrice: {amount: notDeltaPrice.amount, currency: notDeltaPrice.currency}}),
    }
}

export const setPriceFilter = (priceFilter, price) => {
    if (price.amount < priceFilter[0]) {
        priceFilter[0] = price.amount
    }
    if (price.amount > priceFilter[1]) {
        priceFilter[1] = price.amount
    }
}

export const flightSalesTermsRsTransformer = salesTerms => {
    if (!salesTerms) return {}
    const {priceDetails, ...clientSalesTerm} = getClientSalesTerm(salesTerms)
    return {
        price: clientSalesTermTransform(clientSalesTerm),
        priceDetails,
    }
}
