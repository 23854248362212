import {appInstance} from '~/utils/app-accessor'
import {persistentStore, runtimeStore} from '~/store'
import {srcHost as srcHostFn} from '~src/utils/host-adapter'

export const convertPrice = price => {
    const selectedCurrency = persistentStore.currency
    let {amount, currency} = price
    if (price.originalCurrency) {
        amount = price.originalAmount
        currency = price.originalCurrency
    }
    if (selectedCurrency !== currency) {
        if (!runtimeStore.exchangeRates[currency] || !runtimeStore.exchangeRates[selectedCurrency]) {
            return {amount: NaN, currency: selectedCurrency}
        }
        amount =
            Math.round(
                ((amount * runtimeStore.exchangeRates[currency]) / runtimeStore.exchangeRates[selectedCurrency]) * 100
            ) / 100
    } else {
        amount = Math.round(amount * 100) / 100
    }
    return {amount, currency: selectedCurrency}
}

export const price = price => {
    const {amount, currency} = convertPrice(price)
    return priceFormat(amount, currency)
}

export const priceFormat = (amount, currency) => {
    const currencies = {
        USD: 'en-US',
        EUR: 'en-UK',
    }
    if (!currencies[currency]) {
        return `${currency} ${amount}`
    }
    if (typeof amount !== 'string') amount = amount.toFixed(2)
    return appInstance.i18n.n(amount, 'currency', currencies[currency])
}

export const imageUrl = (url, size) => {
    if (url) {
        if (url.indexOf('sunhotels') !== -1 || url.indexOf('tboholidays') !== -1) return url
        return `${runtimeStore.gpBaseUrl}/thumbnails/${size}/${url}`
    }
}

const dateFormatFn = (date, dateFormat) => {
    if (date) {
        let locale = appInstance.i18n.locale
        switch (locale) {
            case 'en':
                locale = 'en-GB'
                break
            case 'ar':
                locale = 'ar-SA'
        }
        return appInstance.$dateFns.format(date, dateFormat, {locale})
    }
    return ''
}

export const dateFormat = date => {
    return dateFormatFn(date, CONFIG.dateFormat.main)
}

export const dateShortFormat = date => {
    return dateFormatFn(date, CONFIG.dateFormat.short)
}

export const dateTimeFormat = date => {
    return dateFormatFn(date, CONFIG.dateFormat.time)
}

export const hoursFromDate = date => {
    return appInstance.$dateFns.format(date, 'HH:mm')
}

export const durationFormat = duration => {
    const minutes = Math.ceil(duration % 60)
    const hours = Math.ceil(duration - minutes) / 60
    return `${hours}${appInstance.i18n.t('date_formats.h')} ${minutes}${appInstance.i18n.t('date_formats.m')}`
}

export const handleNewLine = str => str?.replace(/\n/g, '<br>')

export const stripHtml = html => {
    return handleNewLine(new DOMParser().parseFromString(html, 'text/html').body.textContent || '')
}

export const srcHost = srcHostFn
