import {axiosInstance} from '~/utils/axios-accessor'

import * as authorization from '~/api/authorization/authorization'
import * as registration from '~/api/crmperson/registration'
import * as restorePassword from '~/api/crmperson/restorePassword'
import * as resetPassword from '~/api/crmperson/resetPassword'
import * as locations from '~/api/locations/locations'
import * as searchAccommodation from '~/api/accommodations/searchAccommodation'
import * as partialSearchResults from '~/api/search/partialSearchResults'
import * as hotelInfo from '~/api/accommodations/hotelInfo'
import * as hotels from '~/api/accommodations/hotels'
import * as conditions from '~/api/cancellation/conditions'
import * as cancelService from '~/api/cancellation/cancelService'
import * as currentCancelInfo from '~/api/cancellation/currentCancelInfo'
import * as paymentOptions from '~/api/booking/paymentOptions'
import * as paymentMethods from '~/api/booking/paymentMethods'
import * as checkstatus from '~/api/booking/checkstatus'
import * as prepareAccommodationBook from '~/api/accommodations/prepareAccommodationBook'
import * as book from '~/api/booking/book'
import * as exchangeRates from '~/api/exchangeRates/exchangeRates'
import * as companies from '~/api/companies/companies'
import * as companyRegistration from '~/api/companies/companyRegistration'
import * as tariffDetails from '~/api/accommodations/tariffDetails'
import * as onlinePaymentsInitiate from '~/api/onlinePayments/initiate'
import * as onlinePaymentsComplete from '~/api/onlinePayments/complete'
import * as searchAccommodationResults from '~/api/accommodations/searchAccommodationResults'
import * as popularDestinations from '~/api/cms/popularDestinations'
import * as blog from '~/api/cms/blog'
import * as topTours from '~/api/cms/topTours'
import * as layoutSettings from '~/api/cms/layoutSettings'
import * as infoPage from '~/api/cms/infoPage'
import * as resort from '~/api/cms/resort'
import * as persons from '~/api/persons/persons'
import * as orders from '~/api/orders/orders'
import * as orderItinerary from '~/api/orders/orderItinerary'
import * as downloadOrders from '~/api/orders/downloadOrders'
import * as modifyStatus from '~/api/orders/modifyStatus'
import * as itineraryKey from '~/api/orders/itineraryKey'
import * as airports from '~/api/locations/airports'
import * as voucher from '~/api/documents/voucher'
import * as itinerary from '~/api/documents/itinerary'
import * as additionalOptions from '~/api/accommodations/additionalOptions'
import * as suggestHotels from '~/api/accommodations/suggestHotels'
import * as updateCrmPerson from '~/api/crmperson/update'
import * as updatePerson from '~/api/persons/update'
import * as restoreB2BUserPasswordWithApiKey from '~/api/persons/restoreB2BUserPasswordWithApiKey'
import * as resetB2BUserPasswordWithApiKey from '~/api/persons/resetB2BUserPasswordWithApiKey'
import * as notificationTemplates from '~/api/notificationTemplates/notificationTemplates'
import * as crmPersonPassport from '~/api/crmperson/passport'
import * as personPassport from '~/api/persons/passport'

import * as searchTransfer from '~/api/transfers/searchTransfer'
import * as transferInfo from '~/api/transfers/transferInfo'
import * as prepareBookTransfer from '~/api/transfers/prepareBookTransfer'
import * as trainStations from '~/api/locations/trainStations'
import * as transferAdditionalOptions from '~/api/transfers/transferAdditionalOptions'

import * as searchActivities from '~/api/activities/searchActivities'
import * as prepareActivityBook from '~/api/activities/prepareActivityBook'
import * as activityInfo from '~/api/activities/activityInfo'
import * as corporatePolicyViolationCode from '~/api/corporatePolicyViolationCodes/corporatePolicyViolationCode'
import * as activityAdditionalOptions from '~/api/activities/activityAdditionalOptions'

import * as searchFlight from '~/api/flights/searchFlight'
import * as flightInfo from '~/api/flights/flightInfo'
import * as flightSeatsMap from '~/api/flights/flightSeatsMap'
import * as flightFareOptions from '~/api/flights/flightFareOptions'
import * as airlines from '~/api/flights/airlines'
import * as prepareFlightBook from '~/api/flights/prepareFlightBook'
import * as flightFares from '~/api/flights/flightFares'
import * as searchFlightSchedule from '~/api/flights/searchFlightSchedule'
import * as checkFlightPricesAndAvailability from '~/api/flights/checkFlightPricesAndAvailability'
import * as additionalFlightOptions from '~/api/flights/additionalFlightOptions'
import * as issueFlightTicket from '~/api/flights/issueFlightTicket'
import * as flightPreferences from '~/api/flights/flightPreferences'

import * as report from '~/api/reports/report'
import * as reportParameters from '~/api/reports/reportParameters'
import * as reportsList from '~/api/reports/reportsList'
import * as contractGroups from '~/api/contractGroups/contractGroups'
import * as downloadStatementOfAccount from '~/api/reports/downloadStatementOfAccount'

import * as packageTourInfo from '~/api/package/packageTourInfo'
import * as packageTours from '~/api/package/packageTours'
import * as preparePackageTourBook from '~/api/package/preparePackageTourBook'
import * as searchPackageTours from '~/api/package/searchPackageTours'
import * as updatePackageTourPrice from '~/api/package/updatePackageTourPrice'

import * as invoices from '~/api/invoices/invoices'
import * as printInvoice from '~/api/invoices/printInvoice'
import * as invoiceIssueInfo from '~/api/invoices/invoiceIssueInfo'
import * as issueInvoice from '~/api/invoices/issueInvoice'
import * as invoicePaymentMethods from '~/api/invoices/invoicePaymentMethods'
import * as invoicePaymentByKey from '~/api/invoices/invoicePaymentByKey'

import * as contracts from '~/api/ownProducts/contracts'
import * as touristBookingFields from '~/api/booking/touristBookingFields'

import * as prepareAccommodationModify from '~/api/accommodations/prepareAccommodationModify'
import * as modifyAccommodationService from '~/api/accommodations/modifyAccommodationService'

import * as searchOwnExtraService from '~/api/ownExtraServices/searchOwnExtraService'
import * as ownExtraServiceInfo from '~/api/ownExtraServices/ownExtraServiceInfo'
import * as prepareOwnExtraServiceBook from '~/api/ownExtraServices/prepareOwnExtraServiceBook'
import * as ownExtraServiceAdditionalOptions from '~/api/ownExtraServices/ownExtraServiceAdditionalOptions'

import * as availability from '~/api/ownProducts/availability'
import * as dailyRates from '~/api/accommodationContracts/dailyRates'
import * as prices from '~/api/ownProducts/prices'

import * as messages from '~/api/onlineChat/messages'
import * as message from '~/api/onlineChat/message'
import * as markReadCount from '~/api/onlineChat/markReadCount'

//TODO Separate cms api from gpts api
import * as cmsAuth from '~/api/cms/cmsAuth'
import * as customTranslations from '~/api/cms/customTranslations'
import * as homePageSettings from '~/api/cms/homePageSettings'
import * as hotelSettings from '~/api/cms/hotelSettings'

import * as searchDynamicPackages from '~/api/package/searchDynamicPackages'
import * as prepareDynamicPackageBook from '~/api/package/prepareDynamicPackageBook'
import * as updatePackagePrice from '~/api/package/updatePackagePrice'
import * as updatePackageOffers from '~/api/package/updatePackageOffers'

import * as privateClients from '~/api/crmperson/privateClients'
import {downloadData} from '@/utils/helpers'

const getFilename = response => {
    const disposition = response.headers['content-disposition']
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            return decodeURI(matches[1].replace(/['"]/g, '')).replace('UTF-8', '')
        }
    }
    return ''
}

async function getMethod(url, request = {}, cancelToken) {
    const config = {
        params: request,
    }
    if (cancelToken) {
        config.cancelToken = cancelToken
    }
    return await axiosInstance.$get(url, config)
}

async function postMethod(url, request, config) {
    return await axiosInstance.$post(url, request, config)
}

async function putMethod(url, request, config) {
    return (await axiosInstance.put(url, request, config)).data
}

async function getFile(url, request, filename) {
    const rs = await axiosInstance.get(url, {
        params: request,
        responseType: 'blob',
    })
    downloadData(rs.data, filename || getFilename(rs))
}

async function postFile(url, request, filename) {
    const rs = await axiosInstance.post(url, request, {
        responseType: 'blob',
    })
    downloadData(rs.data, filename || getFilename(rs))
}

export {
    getMethod,
    postMethod,
    putMethod,
    getFile,
    postFile,
    authorization,
    registration,
    restorePassword,
    locations,
    searchAccommodation,
    partialSearchResults,
    hotelInfo,
    hotels,
    conditions,
    cancelService,
    currentCancelInfo,
    paymentOptions,
    paymentMethods,
    checkstatus,
    prepareAccommodationBook,
    book,
    exchangeRates,
    companies,
    companyRegistration,
    tariffDetails,
    onlinePaymentsInitiate,
    onlinePaymentsComplete,
    searchAccommodationResults,
    popularDestinations,
    layoutSettings,
    infoPage,
    resort,
    persons,
    orders,
    orderItinerary,
    downloadOrders,
    airports,
    voucher,
    itinerary,
    additionalOptions,
    suggestHotels,
    updateCrmPerson,
    updatePerson,
    searchTransfer,
    transferInfo,
    prepareBookTransfer,
    searchActivities,
    prepareActivityBook,
    trainStations,
    activityInfo,
    restoreB2BUserPasswordWithApiKey,
    resetB2BUserPasswordWithApiKey,
    resetPassword,
    corporatePolicyViolationCode,
    notificationTemplates,
    searchFlight,
    flightInfo,
    flightSeatsMap,
    flightFareOptions,
    airlines,
    prepareFlightBook,
    flightFares,
    cmsAuth,
    customTranslations,
    homePageSettings,
    hotelSettings,
    searchDynamicPackages,
    prepareDynamicPackageBook,
    updatePackagePrice,
    updatePackageOffers,
    searchFlightSchedule,
    checkFlightPricesAndAvailability,
    additionalFlightOptions,
    privateClients,
    report,
    reportParameters,
    reportsList,
    blog,
    contractGroups,
    topTours,
    packageTourInfo,
    packageTours,
    preparePackageTourBook,
    searchPackageTours,
    updatePackageTourPrice,
    invoices,
    printInvoice,
    invoiceIssueInfo,
    issueInvoice,
    invoicePaymentMethods,
    invoicePaymentByKey,
    contracts,
    touristBookingFields,
    ownExtraServiceInfo,
    prepareAccommodationModify,
    modifyAccommodationService,
    searchOwnExtraService,
    prepareOwnExtraServiceBook,
    ownExtraServiceAdditionalOptions,
    issueFlightTicket,
    activityAdditionalOptions,
    transferAdditionalOptions,
    crmPersonPassport,
    personPassport,
    modifyStatus,
    itineraryKey,
    availability,
    dailyRates,
    prices,
    downloadStatementOfAccount,
    messages,
    message,
    markReadCount,
    flightPreferences,
}
