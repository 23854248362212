import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {flightsStore, persistentStore} from '@/store'
import {B2B_AUTH_EVENT, CHANGE_PRICE_EVENT, EventBus, FILTER_EVENT, RESET, SEARCH_EVENT} from '@/utils/event-bus'
import {filters, searchRequest, searchResponse, searchScheduleResponse} from '@/utils/flights/flights-blank-states'
import {axiosInstance} from '@/utils/axios-accessor'
import {later} from '@/utils/helpers'
import {appInstance} from '@/utils/app-accessor'
import flightsFilter from '@/filters/flightsFilter'
import flightsSort from '@/filters/flightsSort'
import {addSeconds} from 'date-fns'
import Vue from 'vue'

function setSearchResponse(searchResponse) {
    if (flightsStore.searchResponse.offers.length !== searchResponse.offers.length) {
        const initialPrice = flightsStore.searchResponse.filters.price,
            priceFilterValue = flightsStore.filters.price,
            newPriceFilterValue = priceFilterValue.slice()
        if (initialPrice[0] === priceFilterValue[0] || priceFilterValue[0] === 0) {
            newPriceFilterValue[0] = searchResponse.filters.price[0]
        }
        if (initialPrice[1] === priceFilterValue[1] || priceFilterValue[1] === Infinity) {
            newPriceFilterValue[1] = searchResponse.filters.price[1]
        }
        if (priceFilterValue[0] !== newPriceFilterValue[0] || priceFilterValue[1] !== newPriceFilterValue[1]) {
            flightsStore.SET_FILTER({key: 'price', value: newPriceFilterValue})
        }

        const initialDuration = flightsStore.searchResponse.filters.duration,
            durationFilterValue = flightsStore.filters.duration,
            newDurationFilterValue = durationFilterValue.slice()
        if (initialDuration[0] === durationFilterValue[0] || durationFilterValue[0] === 0) {
            newDurationFilterValue[0] = searchResponse.filters.duration[0]
        }
        if (initialDuration[1] === durationFilterValue[1] || durationFilterValue[1] === 9000) {
            newDurationFilterValue[1] = searchResponse.filters.duration[1]
        }
        if (
            durationFilterValue[0] !== newDurationFilterValue[0] ||
            durationFilterValue[1] !== newDurationFilterValue[1]
        ) {
            flightsStore.SET_FILTER({key: 'duration', value: newDurationFilterValue})
        }

        flightsStore.SET_SEARCH_RESPONSE(searchResponse)
        this.filter({offers: searchResponse.offers, filters: flightsStore.filters})
    }
}
//TODO Refactoring partial search with all products
async function partialSearch(rq) {
    let searchResponse
    const {searchKey} = await appInstance.$api.searchFlight.get(rq)
    do {
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource)
            searchResponse = await appInstance.$api.partialSearchResults.get(
                {searchKey, product: 'flight'},
                cancelTokenSource.token
            )
            setSearchResponse.call(this, searchResponse)
            if (!searchResponse.processed) {
                this.SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(
                    later(searchResponse.offers.length > 1 ? CONFIG.search.partialResponseInterval : 3000)
                )
                await this.partialSearchTimeoutInstance.promise
            }
        } catch (e) {
            if (!e) {
                break
            } else if (e.status === 404) {
                //TODO Check 404 - searchKey has expired
                await partialSearch.call(this, rq)
                return
            } else {
                throw e
            }
        }
    } while (!searchResponse.processed)
    if (searchResponse.offers.length) {
        flightsStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), CONFIG.search.offersLifetime))
    }
}

async function scheduleSearch(searchRequest) {
    const searchResponse = await appInstance.$api.searchFlightSchedule.get(searchRequest)
    if (!searchResponse.routes.length) {
        searchResponse.routes = searchScheduleResponse(searchRequest.routes.length).routes
    }
    flightsStore.SET_SEARCH_SCHEDULE_RESPONSE(searchResponse)
    const routeIndex = 0
    flightsStore.RESET_SCHEDULE_FILTERS(routeIndex)
    this.filter({offers: searchResponse.routes[routeIndex].offers, filters: filters()})
}

function newSearch(searchRequest) {
    //TODO Need to refactoring
    this.stopSearch()
    flightsStore.SET_SEARCH_RESPONSE(searchResponse())
    this.SET_OFFERS([])
    this.SET_FLEXIBLE_DATES({
        startDates: [],
        endDates: [],
    })
    this.SET_FLEXIBLE_FLIGHTS([])
    flightsStore.NEW_SEARCH(searchRequest)
}

function newScheduleSearch(searchRequest) {
    if (this.scheduleSearchCTS) this.scheduleSearchCTS.cancel()
    flightsStore.SET_SEARCH_SCHEDULE_RESPONSE(searchScheduleResponse(searchRequest.routes.length))
    //this.SET_SCHEDULE_OFFERS({offers: []})
    this.SET_OFFERS([])
    flightsStore.NEW_SCHEDULE_SEARCH(searchRequest)
}

@Module({name: 'flightsRuntime', stateFactory: true, namespaced: true})
export default class FlightsRuntimeStore extends VuexModule {
    searchType = 'price'
    routes = []

    offers = []
    searchActiveCount = 0
    partialSearchResultsCTS = null
    partialSearchTimeoutInstance = null

    scheduleOffers = []
    scheduleSearchActiveCount = 0
    scheduleSearchCTS = null
    scheduleRouteIndex = 0

    availableOffers = []
    checkAvailabilityActive = false

    flexibleFlights = []
    flexibleDates = {
        startDates: [],
        endDates: [],
    }
    extraBaggageOptions = []

    flightSeatsMap = {}

    @Mutation
    RESET() {
        this.flexibleDates = {
            startDates: [],
            endDates: [],
        }
        this.flexibleFlights = []
        this.searchType = 'price'
        this.searchActiveCount = 0
    }

    @Mutation
    SET_ROUTES(val) {
        this.routes = [...val]
    }

    @Mutation
    SET_ROUTE_DATE({index, date}) {
        this.routes[index].date = date
    }

    @Mutation
    SET_FLEXIBLE_DATES(dates) {
        this.flexibleDates = dates
    }

    @Mutation
    SET_OFFERS(offers) {
        this.offers = offers
    }

    @Mutation
    SET_SCHEDULE_OFFERS({offers: val, route = null}) {
        if (route !== null) {
            Vue.set(this.scheduleOffers, route, val)
        } else {
            const index = this.scheduleOffers.length
            for (let i = 0; i < index; i++) {
                Vue.set(this.scheduleOffers, i, val)
            }
        }
    }

    @Mutation
    SET_AVAILABLE_OFFERS(val) {
        this.availableOffers = val
    }

    @Mutation
    SET_ACTIVE_ROUTE(val) {
        this.scheduleRouteIndex = val
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    START_SCHEDULE_SEARCH() {
        this.scheduleSearchActiveCount++
    }

    @Mutation
    STOP_SCHEDULE_SEARCH() {
        this.scheduleSearchActiveCount--
    }

    @Mutation
    SET_CHECK_AVAILABILITY_ACTIVE(active) {
        this.checkAvailabilityActive = active
    }

    @Mutation
    SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource) {
        this.partialSearchResultsCTS = cancelTokenSource
    }

    @Mutation
    SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(timeoutInstance) {
        this.partialSearchTimeoutInstance = timeoutInstance
    }

    @Mutation
    SET_SEARCH_TYPE(type) {
        this.searchType = type
    }

    @Mutation
    SET_FLEXIBLE_FLIGHTS(flights) {
        this.flexibleFlights = flights
    }

    @MutationAction({mutate: ['routes']})
    async loadRoutes(queryRoutes) {
        try {
            const promises = []
            const routesCodes = []
            queryRoutes.forEach(route => {
                const codes = route.split(',')[0].split('-')
                routesCodes.push({
                    codes,
                    date: route.split(',')[1],
                })
                codes.forEach(code => {
                    const rq = {
                        pattern: code,
                        limit: 1,
                    }
                    promises.push(appInstance.$api.airports.get(rq))
                })
            })
            const routes = await Promise.all(promises)
            const maped = {}
            routes.forEach(([route]) => {
                maped[route.iataCode] = Object.assign({}, route, {parentName: route.countryName})
            })
            const result = routesCodes.map(routeCodes => {
                return {
                    departure: maped[routeCodes.codes[0]],
                    arrival: maped[routeCodes.codes[1]],
                    date: routeCodes.date,
                }
            })
            return {routes: result}
        } catch (e) {
            return {routes: []}
        }
    }

    @MutationAction({mutate: ['extraBaggageOptions']})
    async loadAdditionalFlightOptions(offerKey) {
        try {
            const {extraBaggageOptions} = await appInstance.$api.additionalFlightOptions.get({offerKey})
            if (extraBaggageOptions) {
                return {extraBaggageOptions}
            } else {
                return {extraBaggageOptions: []}
            }
        } catch (e) {
            return {extraBaggageOptions: []}
        }
    }

    @MutationAction({mutate: ['flightSeatsMap']})
    async loadFlightSeatsMap({offerKey, segmentRphs}) {
        try {
            const promises = segmentRphs.map(segmentRph => appInstance.$api.flightSeatsMap.get({offerKey, segmentRph}))
            const result = await Promise.all(promises)

            //const result = []
            //segmentRphs.forEach(rph => result.push(require('~/api/flights/__mocks__/flightSeatsMap.json')))

            const flightSeatsMap = result.reduce((flightSeatsMap, {seatsCabinClasses}, index) => {
                if (seatsCabinClasses) {
                    flightSeatsMap[segmentRphs[index]] = seatsCabinClasses
                }
                return flightSeatsMap
            }, {})
            return {flightSeatsMap}
        } catch (e) {
            return {flightSeatsMap: {}}
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        //TODO Add CHANGE_LOCALE_EVENT handler
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        flightsStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
        newScheduleSearch.call(this, searchRequest())
        this.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    newScheduleSearch() {
        newScheduleSearch.call(this, searchRequest())
    }

    @Action
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await partialSearch.call(this, rq)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }

    @Action
    async scheduleSearch(rq) {
        this.START_SCHEDULE_SEARCH()
        newScheduleSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await scheduleSearch.call(this, rq)
            // eslint-disable-next-line no-empty
        } catch (e) {
            console.error(e)
        } finally {
            this.STOP_SCHEDULE_SEARCH()
        }
    }

    @Action
    async checkAvailability(rq) {
        this.SET_CHECK_AVAILABILITY_ACTIVE(true)
        this.SET_AVAILABLE_OFFERS([])
        flightsStore.SET_AVAILABILITY_RESPONSE(searchResponse())
        flightsStore.SET_AVAILABILITY_REQUEST(rq)
        flightsStore.RESET_AVAILABLE_OFFER_FILTERS()
        EventBus.$emit(SEARCH_EVENT)
        try {
            const rs = await appInstance.$api.checkFlightPricesAndAvailability.post(rq)
            flightsStore.SET_AVAILABILITY_RESPONSE(rs)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.SET_CHECK_AVAILABILITY_ACTIVE(false)
        }
    }

    @Action
    async searchFlexibleFlights(rq) {
        this.SET_FLEXIBLE_FLIGHTS([])
        const {offers} = await appInstance.$api.searchFlight.get(rq)
        const grouped = {}
        offers.forEach(({itinerary}, index) => {
            const {departure} = itinerary[0].segments[0]
            const date = departure.date.split(' ')[0]
            if (!grouped[date]) {
                grouped[date] = []
            }
            grouped[date].push(offers[index])
        })
        const result = {}
        Object.entries(grouped).forEach(val => {
            const grouped = {}
            val[1].forEach(({itinerary}, index) => {
                const lastItinerary = itinerary.length - 1
                const lastSegment = itinerary[lastItinerary].segments.length - 1
                const {arrival} = itinerary[lastItinerary].segments[lastSegment]
                const date = arrival.date.split(' ')[0]
                if (!grouped[date]) {
                    grouped[date] = []
                }
                grouped[date].push(val[1][index])
            })
            result[val[0]] = grouped
        })
        const keys = Object.keys(result)
        keys.sort((a, b) => {
            return appInstance.$dateFns.compareAsc(appInstance.$dateFns.parseISO(a), appInstance.$dateFns.parseISO(b))
        })
        const startDates = keys
        const endDates = new Set()
        Object.values(result).forEach(part => {
            Object.keys(part).forEach(date => {
                endDates.add(date)
            })
        })
        const end = Array.from(endDates).sort((a, b) => {
            return appInstance.$dateFns.compareAsc(appInstance.$dateFns.parseISO(a), appInstance.$dateFns.parseISO(b))
        })
        this.SET_FLEXIBLE_FLIGHTS(result)
        this.SET_FLEXIBLE_DATES({
            startDates,
            endDates: end,
        })
    }

    //TODO !!!! Set offers and filters as fn param
    @Action
    filter({offers, filters}) {
        appInstance.$pool
            .exec(flightsFilter, [offers, filters])
            .then(async filteredOffers => {
                this.sort(filteredOffers)
            })
            .catch(function(err) {
                console.error(err)
            })
            .then(function() {
                appInstance.$pool.terminate() // terminate all workers when done
            })
    }

    @Action
    sort(offers) {
        appInstance.$pool
            .exec(flightsSort, [offers, flightsStore.sortFnName])
            .then(sortedOffers => {
                this.SET_OFFERS(sortedOffers)
                EventBus.$emit(FILTER_EVENT)
            })
            .catch(function(err) {
                console.error(err)
            })
            .then(function() {
                appInstance.$pool.terminate() // terminate all workers when done
            })
    }

    @Action
    stopSearch() {
        if (this.partialSearchResultsCTS) this.partialSearchResultsCTS.cancel()
        if (this.partialSearchTimeoutInstance) this.partialSearchTimeoutInstance.cancel()
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get scheduleSearchActive() {
        return this.scheduleSearchActiveCount > 0
    }

    get isPriceMode() {
        return this.searchType === 'price'
    }

    get flightsPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'flights', query}
        }
    }

    get flightsSchedulePageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'flightsSchedule', query}
        }
    }
}
