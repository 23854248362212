import {Mutation, Module, Action} from 'vuex-module-decorators'
import {searchRequest, searchResponse} from '~/utils/tours/tours-blank-states'
import Vue from 'vue'
import {runtimeStore} from '@/utils/store-accessor'
import {appInstance} from '@/utils/app-accessor'
import {addSeconds, isBefore, parseISO} from 'date-fns'
import {UTCToDate} from '@/utils/helpers'
import {salesTermsRsTransformer} from '@/utils/api-helpers'
import ProductStoreBase from '@/store/modules/productStoreBase'

function getBasketItem(offerKey) {
    return this.basket.find(item => item.offerKey === offerKey) || {searchRequest: searchRequest(), offer: {info: {}}}
}

@Module({name: 'tours', stateFactory: true, namespaced: true})
export default class ToursStore extends ProductStoreBase {
    searchExpirationTime = null
    searchResponse = searchResponse()
    searchRequest = searchRequest()

    prepareBookRequest = {}
    prepareBookResponse = {}

    basket = []

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchExpirationTime = null
        this.searchResponse = searchResponse()
        this.searchRequest = searchRequest
    }

    @Mutation
    RESET() {
        this.searchExpirationTime = null
        this.searchResponse = searchResponse()
        this.searchRequest = searchRequest()

        this.prepareBookRequest = {}
        this.prepareBookResponse = null
    }

    @Mutation
    SET_SEARCH_RESPONSE(searchResponse) {
        this.searchResponse = searchResponse
        this.searchExpirationTime = addSeconds(new Date(), CONFIG.search.offersLifetime)
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP(data) {
        Vue.set(this.prepareBookRequest, data.prop, data.val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    ADD_TO_BASKET(offerKey) {
        this.basket = this.basket.filter(item => isBefore(new Date(), UTCToDate(parseISO(item.offer.expirationTime))))
        if (getBasketItem.call(this, offerKey).offerKey) return
        this.basket.push({
            offerKey,
            offer: {...this.searchResponse},
            searchRequest: {...this.searchRequest},
        })
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const offer = basketItem.offer
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(offer, 'currentPrice', price)
    }

    @Action({rawError: true})
    async prepareBook(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_PREPARE_BOOK_REQUEST(rq)
            const rs = await appInstance.$api.preparePackageTourBook.post(rq)
            this.SET_PREPARE_BOOK_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    get hasOffers() {
        return !!this.searchResponse.offerKey
    }

    get basketItem() {
        return offerKey => getBasketItem.call(this, offerKey)
    }

    get extraPrice() {
        return price => price
    }
}
